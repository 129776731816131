<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Ruta</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
                <v-layout row>
                  <v-flex xs12>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.name')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="name"
                    @input="name = name.toUpperCase()"
                    v-validate="'required'"
                    name="name"
                    v-bind:placeholder="$t('lang.ruta.name')">
                   </v-text-field>
                  </v-flex>
                </v-layout>
                <v-checkbox
                  v-model="ida"
                  label="Ruta de ida"
                  @change="clearFlights"
                ></v-checkbox>
                 <v-layout row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="idaflights"
                      :items="allNewFlights"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      :label="label"
                      item-text="flightNumber"
                      item-value="id"
                      multiple
                      return-object
                      :menu-props="{closeOnContentClick:true}"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeIdaFlight(data.item)"
                        >
                          <v-avatar left>
                            <v-img :src="data.item.avatar"></v-img>
                          </v-avatar>
                          {{ data.item.airline.iata }}-{{ data.item.flightNumber }}, {{ data.item.dateDeparture.substring(0,10) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.airline.iata }}-{{ data.item.flightNumber }}</v-list-item-title>
                            <v-list-item-subtitle >{{ data.item.sourceAirport.city }} - {{ data.item.destinyAirport.city }} - {{ data.item.dateDeparture.substring(0,10) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-layout>

                <v-layout row>
                  <v-col xs12>
                    <v-data-table
                     :headers="flightheaders"
                     :items="idaflights"
                     :items-per-page="5"
                     class="elevation-1"
                    >
                       <template v-slot:item.dateDeparture="{ item }">
                            <td>{{ item.dateDeparture.substr(0,10)}}, {{ item.dateDeparture.substr(11,5)}}</td>
                        </template>
                        <template v-slot:item.dateArrive="{ item }">
                            <td>{{ item.dateArrive.substr(0,10)}}, {{ item.dateArrive.substr(11,5)}}</td>
                        </template>
                        <template v-slot:item.actions="{ item }">
                                 <div class="text-truncate">
                                  <v-icon
                                    medium
                                    class="mr-2"
                                    @click="showDuplicarDialog(item)"
                                  >
                                  mdi-details
                                  </v-icon>
                                  <v-icon
                                    medium
                                    color ="red"
                                    @click="removeFlight(item)"
                                  >
                                  mdi-delete
                                  </v-icon>
                              </div>
                      </template>
                   </v-data-table>
                 </v-col>
                </v-layout>
               <v-layout row wrap>
                <v-flex xs12 sm4>
                <v-checkbox
                  v-model="activa"
                  label="Ruta Activa"
                ></v-checkbox>
                </v-flex>
             </v-layout>
             <v-layout row>
           <!--  <v-layout row>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.tarifa')}}
                   </label>
                   <v-text-field
                    solo
                    type="number"
                    autocomplete="off"
                    id="ident"
                    v-model="tarifa"
                    v-validate="'required'"
                    name="tarifa"
                    v-bind:placeholder="$t('lang.ruta.tarifa')">
                   </v-text-field>
                  </v-flex>
                 <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.fee')}}
                   </label>
                   <v-text-field
                    solo
                    type="number"
                    autocomplete="off"
                    id="ident"
                    v-model="fee"
                    v-validate="'required'"
                    name="fee"
                    v-bind:placeholder="$t('lang.ruta.fee')">
                   </v-text-field>
                  </v-flex>
                </v-layout> -->
                <v-flex xs12>
                <label class="col-sm-6 control-label ">
                  {{$t('lang.persons.remarks')}}
               </label>
                <v-textarea
                    solo
                    id="remarks"
                    v-model="remarks"
                    name="remarks">
                 </v-textarea>
                </v-flex>
            </v-layout>
            <v-layout row>
               <v-btn color="red" @click= "gohome" >
                   <v-icon medium class="mr-2">
                    mdi-close
                   </v-icon>
                   {{$t('lang.itinerary.sclose')}}
               </v-btn>
                <v-spacer></v-spacer>
               <v-btn color="primary" @click= "createRuta" >
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   {{$t('lang.ruta.sbutton')}}
               </v-btn>


            </v-layout>

            </v-container>
           </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_RUTA_MUTATION } from '../constants/graphql'
export default {
    name: 'CreateRuta',
    data() {
        return {
            titulo:"AGREGAR",
                idaflights: [],
                flightArray:[],
                returnflights: [],
                roundtrip: 'false',
                name:'',
                ida:true,
                activa:true,
                remarks:'',
                flights:'',
                dateDeparture:'',
                origen:'',
                destino:'',
                hequipaje:6,
                equipaje:1,
                tarifa:0.0,
                fee:0.0,
                flightheaders: [
                  { text: 'Numero de vuelo', value: 'flightNumber' },
                  { text: 'Aerolinea', value: 'airline.name' },
                  { text: 'Origen', value: 'sourceAirport.name' },
                  {text: 'Hora de Salida', value: 'dateDeparture'},
                  { text: 'Destino', value: 'destinyAirport.name' },
                  {text: 'Hora de Llegada', value: 'dateArrive'},
                  {text: 'Action', value: 'actions'},
                ],
            }
        },
   async created () {

        },
    apollo: {
    allNewFlights: gql`query {
    allNewFlights
      {
        id
        flightNumber
        capacity
        airline{
          id
          name
          iata
        }
        sourceAirport
        {
          id
          name
          city
          iataCode
        }
         dateDeparture
         destinyAirport
        {
          id
          name
          city
          iataCode
        }
        dateArrive
      }
     }`,
    allEquipajes: gql`query {
    allEquipajes
      {
        id
        name
        quantity
        weight
        unit{
            id
            name
        }
        hand
      }
     }`,
  },
  computed: {
        label(){
        if(this.ida == false){
            return 'SELECCIONE LOS VUELOS DE REGRESO'
            }
        else{
            return 'SELECCIONE LOS VUELOS DE IDA'
            }
        },
        allEquipajesBodega(){return this.allEquipajes.filter(equipaje => equipaje.hand == 0)},
        allEquipajesHand(){return this.allEquipajes.filter(equipaje => equipaje.hand == 1)}
      },
  methods:{
        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
      //Metodo que debe llamar a addBooking para crear todos las reservas del itinerario...
      clearFlights(){
        this.flightArray.length = 0
        this.idaflights.length = 0
        this.flights =''
        console.log(this.flights)
      },
      removeIdaFlight (item) {
        const index = this.idaflights.indexOf(item)
        if (index >= 0) this.idaflights.splice(index, 1)
      },
      removeReturnFlight (item) {
        const index = this.returnflights.indexOf(item)
        if (index >= 0) this.returnflights.splice(index, 1)
      },
    createRuta(){

        this.idaflights.forEach(flight => {
            const newFlight ={
                id:flight.id,
                iata:flight.airline.iata,
                number:flight.flightNumber,
                dateDeparture : flight.dateDeparture,
                capacity: flight.capacity
            }
            this.flightArray.push(newFlight)
        })
        this.flights = JSON.stringify(this.flightArray)
        this.dateDeparture = this.idaflights[0].dateDeparture
        this.origen = this.idaflights[0].sourceAirport.id
        this.destino = this.idaflights[this.idaflights.length-1].destinyAirport.id
        this.stops = this.idaflights.length-1
        console.log(this.flights)
        this.addRuta()
        this.$router
            .push('/allRutas')
    },
    addRuta(){
        const {
        name,
        flights,
        activa,
        dateDeparture,
        ida,
        remarks,
        destino,
        origen,
        stops,
        equipaje,
        tarifa,
        fee
        } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_RUTA_MUTATION,
        variables: {
            name,
            flights,
            dateDeparture,
            activa,
            ida,
            remarks,
            destino,
            origen,
            stops,
            equipaje,
            tarifa,
            fee
        }
        }).then((data)=>{
            console.log(data)
            this.clearFlights()
            this.name = ''
        }).catch((error) => {
              // Error
              console.error(error)
              // We restore the initial user input
            })

    },
  },
 }
</script>