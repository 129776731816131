<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
     <v-dialog v-model="searching" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Buscar Vuelos</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>

              <v-layout row>
                  <v-flex xs12 sm3>
                   <v-select
                           v-model="origin"
                           label="Ciudad de Origen"
                           v-validate="'required'"
                           name="origineselect" :items="allAirports" item-value = "icaoCode" :item-text="item => item.city +' ('+item.icaoCode+')'" solo>
                   </v-select>
                 </v-flex>
                <v-flex xs12 sm3>
                   <v-select
                           v-model="destiny"
                           label="Ciudad de Destino"
                           v-validate="'required'"
                           name="destinyeselect" :items="allAirports" item-value = "icaoCode" :item-text= "item => item.city +' ('+item.icaoCode+')'" solo>
                   </v-select>
                  </v-flex>
                      <!--Departure date picker-->
                  <v-flex xs12 sm2>
                  <v-dialog
                    ref="dialog"
                    v-model="departure_dialog"
                    :return-value.sync="departure_date"
                    persistent
                    lazy
                    full-width
                    width="310px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="departure_date"
                        label="Saliendo..."
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="departure_date"
                      width="290"
                      class="mt-3"
                      @change="departure_date_save"
                    >
                     <!-- <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="departure_dialog = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="departure_date_save">OK</v-btn>-->
                    </v-date-picker>
                  </v-dialog>
                 </v-flex>
                  <!--Arrive date picker-->
                  <v-flex xs12 sm2>
                  <v-dialog
                    ref="dialogReturn"
                    v-model="arrive_dialog"
                    :return-value.sync="arrive_date"
                    persistent
                    lazy
                    full-width
                    width="310px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="arrive_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            label="Fecha de regreso"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="arrivepicker"
                          v-model="arrive_date"
                          :min="departure_date"
                          @change="arrive_date_save"
                        ></v-date-picker>
                      </v-dialog>
                     </v-flex>

                <v-flex xs12 sm2>
                   <v-text-field
                    solo
                    autocomplete="false"
                    id="flightcapacity"
                    v-model="capacity"
                    v-validate="'required'"
                    name="flightcapacity"
                    label="Personas..."
                    type="number"
                    v-bind:placeholder="$t('lang.flight.capacity')">
                   </v-text-field>
                   </v-flex>

            </v-layout>
            <v-dialog v-model="alert" max-width="500px">
            <v-card
                color="green"
                dark
              >
                <v-card-text color="white">
                    <v-icon medium class="mr-2">
                        mdi-check-circle
                       </v-icon>
                    <span class="text-h6" >Vuelo Guardado</span>
                </v-card-text>
            </v-card>
           </v-dialog>
            <v-dialog v-model="errorAlert" max-width="500px">
                 <v-card color="red" dark>
                  <v-card-title >Error</v-card-title>
                  <v-card-text color="white">{{graphqlError}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="errorAlert = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>


            <v-layout row>
                   <v-btn color="primary"  >
                       <v-icon medium class="mr-2">
                        mdi-clear
                       </v-icon>
                       Limpiar
                   </v-btn>
                   <v-spacer></v-spacer>
                   <v-btn color="primary"  @click = "amadeusSearch">
                       <v-icon medium class="mr-2">
                        mdi-magnify
                       </v-icon>
                       Buscar
                   </v-btn>
            </v-layout>
           </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout >
        <v-layout row offset-sm2 align-center justify-center>
            <v-flex v-for="flightOffer in flights" :key="flightOffer.id" xs12 sm8>
            <v-toolbar dark color="primary">
                   <v-toolbar-title>Oferta de Vuelos, {{flightOffer.oneWay ? "Solo ida" : "Ida y Regreso"}}, Disponibilidad: {{flightOffer.numberOfBookableSeats}}, Precio: {{flightOffer.price.total}} {{flightOffer.price.currency}}</v-toolbar-title>
                   <v-spacer></v-spacer>
                <v-btn color="green accent-3"  @click="booking(flightOffer)"><v-icon
                        medium
                        class="mr-2"
                        >
                      mdi-check
                      </v-icon>
                    Reservar
                </v-btn>
            </v-toolbar>
                <v-card v-for="(item, index) in flightOffer.itineraries" :key="item.id">
                  <v-card-title >{{index==0 ? "Vuelos de Ida" : "Vuelos de Regreso"}}, Duracion:{{item.duration}}  </v-card-title>
                  <v-card-text v-for="segment in item.segments" :key="segment.id" color="white">
                      <img class="product_image" :src="getUrl(segment.operating.carrierCode)" width=" 80px">
                      {{segment.operating.carrierCode}}-{{segment.number}}
                      Salida: {{segment.departure.iataCode}} el {{segment.departure.at.substr(0,10)}} a las {{segment.departure.at.substr(11,5)}}
                      Llegada: {{segment.arrival.iataCode}} el {{segment.arrival.at.substr(0,10)}} a las {{segment.arrival.at.substr(11,5)}}
                  </v-card-text>
                  <v-card-actions>
                        <v-btn color="green accent-3"  @click="crearRuta(index, item)"><v-icon
                        medium
                        class="mr-2"
                        >
                      mdi-airplane-settings
                      </v-icon>
                    Crear Ruta
                </v-btn>
                  </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>

  </v-container>
</template>

<script>

import gql from 'graphql-tag'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
import { CREATE_FLIGHTS_MUTATION } from '../constants/graphql'
import { CREATE_RUTAS_MUTATION } from '../constants/graphql'
//import axios from 'axios'
export default {
    name: 'Amadeus',
  setup() {
    const userStore = useUserStore();
    const info  = computed(() => userStore.getUser);
    return { userStore, info };
  },

          data() {
            return {
               groups:[],
               idAdmin:false,
                titulo:"AGREGAR",
                arrive_dialog: false,
                flightnumber: '',
                airline: '',
                departure_date: null,
                arrive_date: null,
                remarks: '',
                departure_dialog: false,
                capacity: '',
                destiny:'',
                origin:'',
                timeDeparture: null,
                menu: false,
                modal: false,
                timeArrive: null,
                menu2: false,
                modal2: false,
                minTimeArrive: null,
                arriveDateTime: null,
                departureDateTime: null,
                alert: false,
                errorAlert: false,
                flightId: null,
                routeDialog: false,
                ida: true,
                flights : [],
                itineraries : [],
                dateDeparture:null,
                origen : null,
                destino : "",
                stops : 0,
                name :  "",
                activa: true,
                equipaje:1,
                graphqlError: "",
                terminal:1,
                allTerminals:[],
                repetir: 1,
                searching: false,
                confirmData:'',
                flightsForRoute: [],
               }
            },
    async created(){
      },
   async mounted () {
      if(this.groups.filter(group => group.id == 1).length > 0)
      {
        this.isAdmin = true
      }
      else{
        this.isAdmin = false
      }
      console.log("isAdmin: "+this.isAdmin)
    },
    computed: {

    },
    apollo: {
    allAirports: gql`query {
      allAirports {
        id
        name
        city
        country
        iataCode
        icaoCode
      }
    }`,
    allAirlines: gql`query {
      allAirlines {
        id
        name
        iata
        icao
      }
    }`,
  },
  watch: {
      arrive_menu (val) {
        val && setTimeout(() => (this.$refs.arrivepicker.activePicker = 'YEAR'))
      },
      departure_menu (val) {
        val && setTimeout(() => (this.$refs.departurepicker.activePicker = 'YEAR'))
      },
    },
  methods:{

    hideAlert: function () {
      console.log('Hide')
      window.setInterval(() => {
        this.alert = false;
      }, 2000)
    },
    checkAirline(){
        return true
    },
    checkAirport(){
        return true
    },
    async crearRuta(index, item){
      if(index == 0){
       console.log("Ruta de Ida")
       item.segments.forEach(segment => {
         console.log(segment.number)
         this.addFlight(segment)
         .then(() =>
         {
            this.conformRuta()
         })

         })

      }
      else
       {
        console.log("Ruta de regreso")
        item.segments.forEach(segment => console.log(segment.number))
       }
    },
    async addFlight(segment){
        console.log(this.allAirlines.filter(airline => airline.iata == segment.operating.carrierCode)[0].id)
        let arriveDateTime = segment.arrival.at
        let departureDateTime = segment.departure.at
        let flightnumber = segment.number
        let airline = this.allAirlines.filter(airline => airline.iata == segment.operating.carrierCode)[0].id
        console.log(airline)
        let origin = this.allAirports.filter(airport => airport.icaoCode == segment.departure.iataCode)[0].id
        console.log(origin)
        let destiny = this.allAirports.filter(airport => airport.icaoCode == segment.arrival.iataCode)[0].id
        let remarks = "Amadeus"
        let capacity= 50
        let terminal = segment.departure.terminal
        let repetir = 1

     this.$apollo.mutate({
        mutation: CREATE_FLIGHTS_MUTATION,
        variables: {
            flightnumber,
            airline,
            origin,
            destiny,
            departureDateTime,
            remarks,
            arriveDateTime,
            capacity,
            terminal,
            repetir
        }
        }).then((data)=>{
            this.alert=true
            this.hideAlert()
            this.flightsForRoute.push(data)
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              this.graphqlError = error
              this.errorAlert=true
              // We restore the initial user input
            })
    },

    conformRuta(){
        console.log(this.flightsForRoute)
        var flightArray = []
        this.flightsForRoute.forEach(data => {
        console.log(data)
        const newFlight ={
                id:data.data.createFlights.flightList[0].id,
                iata:data.data.createFlights.flightList[0].airline.iata,
                number:data.data.createFlights.flightList[0].flightNumber,
                dateDeparture : data.data.createFlights.flightList[0].dateDeparture.substr(0,10),
                capacity: data.data.createFlights.flightList[0].capacity
            }
        flightArray.push(newFlight)
        this.flights = JSON.stringify(flightArray)
        })
        console.log(this.flights)
        this.dateDeparture = flightArray[0].dateDeparture
        this.origen = this.flightsForRoute.data.createFlights.flightList[0].sourceAirport.id
        this.destino = this.flightsForRoute.data.createFlights.flightList[this.flightsForRoute.length-1].destinyAirport.id
        this.stops = 0
        this.name = this.flightsForRoute.data.createFlights.flightList[0].airline.iata+flightArray[0].number.toString()+" "+this.flightsForRoute.data.createFlights.flightList[0].sourceAirport.icaoCode+"-"+this.flightsForRoute.data.createFlights.flightList[this.flightsForRoute.length-1].destinyAirport.icaoCode
        console.log(this.flights)
        console.log(this.name)
        this.addRuta()
        this.$router
            .push('/allRutas')
    },
    addRuta(){
        const {
        name,
        flights,
        dateDeparture,
        ida,
        destino,
        origen,
        stops,
        remarks,
        activa,
        equipaje,
        repetir
        } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_RUTAS_MUTATION,
        variables: {
            name,
            flights,
            dateDeparture,
            ida,
            destino,
            origen,
            stops,
            remarks,
            activa,
            equipaje,
            repetir
        }
        }).then((data)=>{
            console.log("Ruta:")
            console.log(data)
            }).catch((error) => {
              // Error
              console.error(error)
              // We restore the initial user input
            })

    },
    getUrl(code){
      if(code == "T9")
         return "https://turpialairlines.com/img/logo-turpial.png"
      if(code=="S6")
         return "https://airhex.com/images/airline-logos/sunrise-airways.png"
      if(code=="Y2")
         return "https://aircentury.com/wp-content/uploads/2023/09/logotipo-air-century-1.svg"
      if(code=="8W")
         return "https://flyallways.com/wp-content/uploads/2022/09/FAW-logo-1024x359-1.png"
      if(code == "DO")
         return "https://skyhighdominicana.com/wp-content/uploads/2023/04/SKYhigh-Logo-SKYhigh.svg"
      if(code == "WN")
         return "https://logos-world.net/wp-content/uploads/2020/10/Southwest-Airlines-Logo-120x67.png"
      if(code=="VB")
        return "https://media.staticontent.com/media/pictures/b7ee1c9a-49b9-4724-8c0e-d4ae07e938e7"
      else
      return "https://s1.apideeplink.com/images/airlines/" + code + ".png"
    },
    arrive_date_save() {
      if(this.arrive_date == this.departure_date)
        {
          this.minTimeArrive = this.timeDeparture
        }
        this.$refs.dialogReturn.save(this.arrive_date)
      },
      arrive_time_save (timeArrive) {
        this.$refs.dialogTA.save(timeArrive)
        this.arriveDateTime = this.arrive_date+'T'+timeArrive
      },
      departure_date_save() {
        this.$refs.dialog.save(this.departure_date)
        this.departure_menu = false
      },
      departure_time_save (timeDeparture) {
        this.$refs.dialogTD.save(timeDeparture)
        this.departureDateTime = this.departure_date+'T'+timeDeparture
      },
      async amadeusSearch(){
        this.searching = true
        await this.$apollo.query({
            query: gql`query ($origen: String!,$destino: String,!$date: String, $returndate:String, !$adults: Int!) {
                allAmadeusFlights(origin: $origen, destiny:$destino, flightdate: $date, flightreturndate:$returndate, adults : $adults)
            }`,
            variables: {
              origen: this.origin,
              destino: this.destiny,
              date: this.departure_date,
              returndate: this.arrive_date,
              adults:this.capacity
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data)
               this.flights = JSON.parse(data.data.allAmadeusFlights)

               this.searching = false
               console.log(this.flights)
            }).catch((error) => {
              // Error
              //alert("algo fue mal)
              console.error("Este es el Error: "+error)
              // We restore the initi
              })
      },

      async booking(selectedFlight){
       await this.$apollo.query({
            query: gql`query ($flight: String!) {
                amadeusPriceConfirm(flight: $flight)
            }`,
            variables: {
              flight: selectedFlight,

            },fetchPolicy: "network-only"
            }).then((data)=> {
               this.confirmData = JSON.parse(data.data.amadeusPriceConfirm)
               console.log(this.confirmData)
               if(confirm("Vuelo seleccionado disponible. Precio final: "+this.confirmData.price.total+this.confirmData.price.currency))
                   this.bookingFlights()
            }).catch((error) => {
              // Error
              //alert("algo fue mal)
              console.error("Este es el Error: "+error)
              // We restore the initi
              })
      },
      bookingFlights(){
         return 0
      }



  }
 }
</script>