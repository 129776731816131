import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'
import * as VeeValidate from 'vee-validate';
import BootstrapVue from "bootstrap-vue";
import 'core-js/stable'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime'; // To ensure that regeneratorRuntime is defined globally
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import i18n from './lang'
import '@mdi/js'
import {PiniaVuePlugin } from 'pinia'
import pinia from './pinia.js'
import JsonExcel from "vue-json-excel";
import router from '@/router'

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Vuetify)
Vue.use(PiniaVuePlugin)

Vue.use(VeeValidate, {
	// Podría ser blur, change, etcétera. Si está vacío
	// entonces se tiene que validar manualmente
	events: "change|blur|keyup",
});


new Vue({
  vuetify : new Vuetify(),
  pinia,
  router,
  i18n,
  apolloProvider: createProvider({
    //httpEndpoint: 'http://'+window.location.hostname+':8000/graphql',
    httpEndpoint: 'https://traveladmin.dcano14k.com/graphql',
    //httpEndpoint: 'https://adriitours.alwaysdata.net/api/graphql',
    wsEndpoint: null,
  }),


  render: h => h(App),
  created() {
    if (performance.navigation.type == 1) {
        if (confirm('Recargar sitio?. Los cambios no se guardaran.')) {
          this.$router.push({path: '/login'})
        } else {
          console.log('reload page now');
      }
   }
  }
}).$mount('#app')
