<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title>Lista de Pasajeros</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addPax"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           Agregar Pasajero
       </v-btn>

       <v-btn
       color="primary"
       @click= "showBL"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-cancel
       </v-icon>
           BlackList
       </v-btn>
   </v-app-bar>
         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
             :headers="headers"
             :item-class= "row_classes"
            :items="allPaxList"
            :items-per-page="10"
            :search="search"
             :loading="loading"
            class="elevation-1"



        >
            <template v-slot:item.createdBy.username="{ item }">
                <td>{{ item.createdBy.username }}</td>
            </template>

            <template v-slot:item.actions="{ item }">
                     <div >
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showDuplicarDialog(item)"
                      >
                      mdi-details
                      </v-icon>
                      <v-icon v-if="item.createdBy.username == username || isAdmin"
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-pencil
                      </v-icon>
                  </div>
          </template>
   </v-data-table>

   <v-dialog v-model="dialogDuplicar" max-width="500px" max-height="700px" v-if="pax">
                <v-card>
                  <v-card-title>Detalles del Pasajero {{pax.civil.name}} {{ pax.firstName }} {{ pax.familyName }}</v-card-title>
                    <v-card-text>Pasaporte: {{ pax.passportNumber }}</v-card-text>
                    <v-card-text>Fecha de Expedicion: {{ displayableDate(pax.dateExpired) }}</v-card-text>
                    <v-card-text>Fecha de nacimiento: {{ displayableDate(pax.dateBorn) }}</v-card-text>
                    <v-card-text>Nacionalidad: {{ pax.nacionality.nationality }}</v-card-text>
                    <v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>
                    <v-card-text>Creado Por: {{ pax.createdBy.username }}</v-card-text>
                    <v-card-text>Email: {{ pax.email }}</v-card-text>
                    <v-card-text>Movil: {{ pax.phoneNumber }}</v-card-text>
                    <v-img
                                max-height="500"
                                max-width="500"
                            :src="imageUrl"

                    />
                    <v-card-text v-if=" imageUrlExt == 'pdf'">Nombre del fichero: {{fileName}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="downloadPp()" v-if = "imageUrl">Descargar</v-btn>
                    <v-btn color="primary" text @click="dialogDuplicar = false">Aceptar</v-btn>
                    <v-btn color="primary" text @click="detalles()" v-if = "pax.is_editable">Detalles"</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Edicion</v-card-title>
                  <v-card-text>Desea Editar el Pasajero?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="editItem()">Editar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {PASSENGER_LIST } from '../constants/graphql'
import {PAX_IMG } from '../constants/graphql'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";


export default {
  name: 'PassengerList',
            setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
  data() {
      return {
            search: '',
            headers: [
           { text: 'First name', value: 'firstName' },
           { text: 'Last name', value: 'familyName' },
          { text: 'Document Number', value: 'passportNumber' },
          { text: 'Mobile phone number', value: 'phoneNumber' },
          { text: 'Creado Por', value: 'createdBy.username' },
          {text: 'Action', value: 'actions'},
        ],
        dialogDelete: '',
        dialogDuplicar:'',
        pax: null,
        username: (JSON.parse(this.userStore.getUser)).username,
        groups: (JSON.parse(this.userStore.getUser)).groups,
        myPax: true,
        isAdmin:false,
        allPaxList:[],
        fileName:'',
        imageUrl:'',
        imageUrlExt:'',
        loading:false,
     }
  },
  components: {

  },
  props: {
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    registeredPaxs () {
         this.loading == true
        //return this.paxs
      if(this.isAdmin)
         return this.allPaxList
      else
         return this.allPaxList.filter(paxs => paxs.createdBy.username == this.username)
    },

  },
  async mounted () {
      if(this.groups.filter(group => group.id == 1).length > 0)
      {
        this.isAdmin = true
      }
      else{
        this.isAdmin = false
      }
      console.log("isAdmin: "+this.isAdmin)
      this.loadPaxList()
    },
  methods: {
    row_classes(item) {
        if(item.blackListed){
          const rowclass = "disableRow"
          return rowclass
        }
    },
    showBL(){
      this.$router.push('/blacklist')
    },
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
       selectItem: function(item) {
          if (confirm('Seleccionar este pasajero? ' + item.firstName +" "+item.familyName)) {
                //this.$emit('paqueteselect', subscr.id)
                this.$router.push('/pax/'+ item.passportNumber)
           }
        },

        addpkg: function() {
        },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addPax(){if(confirm("Desea agregar un pasajero.")) this.$router.push('/newPax')},

       async loadPaxList(){
         this.loading = true
         //var user = this.username
         await this.$apollo.query({
                query: PASSENGER_LIST,
                fetchPolicy: "network-only",
                }).then((data)=>{
                this.allPaxList = data.data.allPassengers
                this.loading = false
                })
       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },

        showDeleteDialog(item) {
            this.pax = item
            this.dialogDelete = !this.dialogDelete
        },

         async showDuplicarDialog(item) {
            this.pax = item
            this.imageUrl = ""
            await this.$apollo.query({
                query: PAX_IMG,
                fetchPolicy: "network-only",
                variables:{
                  ppnumber: item.passportNumber
                }
                }).then((data)=>{
                this.imageUrl = "https://traveladmin.dcano14k.com/media/" + data.data.passportImageByPp.logo
                this.imageUrlExt = this.imageUrl.split('.')
                this.fileName = data.data.passportImageByPp.logo
                this.imageUrlExt = this.imageUrlExt.slice(-1)[0]
                console.log(this.imageUrl)
                })
                .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
            this.dialogDuplicar = !this.dialogDuplicar
        },
        downloadPp(){
           if(confirm("Desea descargar el documento del pasaporte?"))
               {
                window.open(this.imageUrl, '_blank')
               }
        },
        paxEditionEstatus(item){
             if(item.is_editable) {return "Borrador"} else {return "Cerrado"}
        },

        detalles: function(){
            this.$router.push('/pax/'+ this.pax.passportNumber)
            this.dialogDuplicar = false
          },

        editItem() {
            this.$router.push('/editpax/'+ this.pax.id)
            this.dialogDelete = false
        },

        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.disableRow{
  color: black;
  background-color: red;
}
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
