<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear User</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>

            <v-layout row>
                  <v-flex xs12 sm4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.username')}}
                   </label>
                   <v-text-field
                        solo
                        autocomplete="off"
                        id="ident"
                        v-model="profile.username"
                        v-validate="'required'"
                        name="ident"
                        v-bind:placeholder="$t('lang.persons.username')">
                   </v-text-field>
                    </v-flex>
                   <v-flex xs12 sm4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.first_name')}}
                   </label>
                   <v-text-field
                        solo
                        autocomplete="off"
                        id="ident"
                        v-model="profile.firstname"
                        @input="profile.firstname = profile.firstname.toUpperCase()"
                        v-validate="'required'"
                        name="ident"
                        v-bind:placeholder="$t('lang.persons.first_name')">
                   </v-text-field>
                    </v-flex>
               <v-flex xs12 sm4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.last_name')}}
                   </label>
                   <v-text-field
                        solo
                        autocomplete="off"
                        id="ident"
                        v-model="profile.lastname"
                        @input="profile.lastname = profile.lastname.toUpperCase()"
                        v-validate="'required'"
                        name="ident"
                        v-bind:placeholder="$t('lang.persons.last_name')">
                   </v-text-field>
                    </v-flex>
                </v-layout>
  <v-layout row>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.password1')}}
                   </label>
                   <v-text-field
                          label="Contrasena"
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            hint="At least 8 characters"
                            class="input-group--focused"
                            @click:append="show3 = !show3"
                            v-model="profile.password1"
                            min="4"
                            required
                        ></v-text-field>
                    </v-flex>
                   <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.password2')}}
                   </label>
                   <v-text-field
                          label="Contrasena"
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            hint="At least 8 characters"
                            class="input-group--focused"
                            @click:append="show3 = !show3"
                            v-model="profile.password2"
                            min="4"
                            required
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 sm6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.email')}}
                   </label>
               <v-text-field

                    solo
                    id="email"
                    v-model="profile.email"
                    v-validate="'required|email'"
                    name="email">
               </v-text-field>
                    </v-flex>
                 <v-flex xs12 sm6>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.movil')}}
               </label>
                 <v-text-field

                    solo
                    id="movil"
                    v-model="profile.movil"
                    v-validate="'required'"
                    name= "movil">
                 </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                  <v-flex xs12 sm6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.codigo')}}
                   </label>
               <v-text-field

                    solo
                    id="codigo"
                    v-model="profile.codigo"
                    v-validate="'required'"
                    name="codigo">
               </v-text-field>
             </v-flex>
             <v-flex xs12 sm6>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.website')}}
               </label>
                 <v-text-field

                    solo
                    id="website"
                    v-model="profile.website"
                    v-validate="'required'"
                    name= "website">
                 </v-text-field>
                </v-flex>
              </v-layout>
            <v-layout row>
                  <v-flex xs12>
                <label class="col-sm-6 control-label ">
                  {{$t('lang.persons.remarks')}}
               </label>
                  <v-textarea
                    solo
                    id="remarks"
                    v-model="profile.remarks"
                    name="remarks">
                 </v-textarea>
                    </v-flex>
            </v-layout>
       <v-btn color="primary" @click= "addPax" ><v-icon medium class="mr-2">
            mdi-save
       </v-icon>
           SALVAR
       </v-btn>

            </v-container>
           </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { CREATE_SELLER_MUTATION } from '../constants/graphql'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'CreatePax',
    setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
    data() {
        return {
            titulo:"AGREGAR",
                created_by: (JSON.parse(this.userStore.getUser)).username,
                issue_menu: false,
                profile:{
                    username: '',
                    firstname: '',
                    lastname: '',
                    remarks: '',
                    codigo: '',
                    email: '@',
                    movil: '+',
                    website: '',
                    fullname: ''
                },
                      valid: false,
                e1: false,
                show3: false,
                rules: {
                  required: value => !!value || 'Required.',
                  min: v => v.length >= 8 || 'Min 8 characters',
                  emailMatch: () => (`The email and password you entered don't match`),
                },
                titles: ['CHILD','MISTER','MISSIS','INFANT'],
               }
            },

  methods:{
    expire_date_save (expire_date) {
        this.$refs.expire_menu.save(expire_date)
      },
    born_date_save (born_date) {
        this.$refs.born_menu.save(born_date)
      },
    issue_date_save (issue_date) {
        this.$refs.issue_menu.save(issue_date)
      },
    addPax(){
        this.profile.fullname = this.profile.firstname +' '+this.profile.lastname
        const { profile
        } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_SELLER_MUTATION,
        variables: {
            profile
        }
        }).then(()=>{
          this.$emit('hideform')
        })



    },
  },
 }
</script>