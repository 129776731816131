<template >
    <div id="wrapper">
     <!--   <printPage v-if="confirmed" ref="frame" />-->
        <div id="toExport" ref="toPrint" class="col-sm-12" v-if="itinerary">

            <v-row>
                <v-spacer></v-spacer>
                <v-col><div align="left">
              <v-img
                  max-height="400"
                  max-width="800"
                  class=" align-end"
                  :src="require('../assets/logoblanco.png')">
                  </v-img>
              </div></v-col>

       <v-spacer></v-spacer>
    <v-col><div align="right" pad="10">
        <v-card elevation="0">
            <v-img
              max-height="550"
              max-width="600"
              :src="require('../assets/hostaltopright.png')"
              class=" align-end"

            >
                <v-card-title><h3>Managua, {{today.substr(0,10)}}</h3></v-card-title>
            </v-img>
         </v-card>
      </div></v-col></v-row>

  <v-card elevation="0">

   <v-card-title align="center">
          <v-spacer/><span class="subheading" ><h2>RESERVA DE HOSTAL</h2></span><v-spacer/>
   </v-card-title>
    <v-row xs6>
        <v-col xs3>
            <v-card-title align="center"><v-spacer/><span class="subheading" ><h3>Estimado (a) Srs. (a). </h3></span></v-card-title>
        </v-col>
        <v-col xs9>
            <v-list>
                <v-list-item v-for="set in passengerSet" :key="set.pax.id"><h3>{{set.pax.firstName}} {{set.pax.familyName}}</h3></v-list-item><v-spacer/>
            </v-list>
        </v-col>
    </v-row>
    <v-card-title><h3>Sera un placer recibirlo en nuestro establecimiento LE VOYAGEUR HOTEL</h3></v-card-title>
    <v-card-title><h3>Por medio de la presente tenemos el agrado de confirmar su reserva segun los siguientes datos:</h3></v-card-title>
    <v-card-title><h3>Dia de llegada: {{ this.dateArrive.substr(0,10) }}</h3></v-card-title>
    <v-card-title><h3>Dia de salida: {{ this.dateLeave.substr(0,10) }}</h3></v-card-title>
    <v-card-title><h3>Alojamiento: LE VOYAGEUR HOTEL</h3></v-card-title>
    <v-card-title><h3>No de confirmación: {{ passengerSet[0].pax.passportNumber }}</h3></v-card-title>
    <v-card-title><h3>Dirección: "Semáforos La Subasta 900 Mtrs Al Sur Hotel Le Voyageur"</h3></v-card-title>
    <v-card-title><h3>Managua, Nicaragua</h3></v-card-title>
  </v-card>




   <v-card elevation="0">
   <v-row >
   <v-spacer/><v-col >
   <v-card-title class="text-right">
          <v-spacer/><span class="heading"><h2>Nuestras Tarifas Incluyen</h2></span>
    </v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>Aire acondicionado</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>Baño privado</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>TV pantalla plana</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>Armario</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>Toalla</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>Mesa y silla</h3></v-card-title>
    <v-card-title class="text-right"><v-spacer/><v-icon>mdi-arrow-right-circle</v-icon><h3>WiFi</h3></v-card-title>
    </v-col>
  </v-row>
<v-spacer></v-spacer>
       <!-- <div style="break-after:page"></div>-->

  </v-card>
  <v-card flat elevation="0">

       <v-img :src="require('../assets/hostalfooter.png')"
              max-width="100%"
   ></v-img>
   </v-card>
            <v-spacer><v-divider inset></v-divider> </v-spacer>
   </div>
    <v-card flat elevation="0">
        <v-row>
          <v-btn v-if="editionStatus==2" @click="exportar" primary >Exportar</v-btn>
          <!--<v-btn @click="imprimir" primary v-if="confirmed">Imprimir</v-btn>-->

          <v-spacer></v-spacer>
           <v-btn color="red" @click= "gohome" >
               <v-icon medium class="mr-2">
                mdi-close
               </v-icon>
               {{$t('lang.itinerary.sclose')}}
           </v-btn>
            </v-row>
        </v-card>



  </div>

</template>

<script>
import gql from 'graphql-tag'
/*import jsPDF from 'jspdf'
import html2canvas from "html2canvas"
import printPage from '@/components/printPAge.vue'*/
export default {
  name: 'HostalBooking',
  components: {
       // printPage
  },
  data () {
    return {
      confirmed: false,
      sortPaxBy: 'pax.familyName',
      sortDesc: false,
      value: '',
      size: 500,
      exportTo : false,
      itinerary: null,
      idaBookings: [],
      returnBookings: [],
      passengerSet: [],
      uniquePassengerSet: [],
      showAllFlights:false,
        idaPaxBookings:[],
        returnPaxBookings:[],
        booking:'',
        status: '',
        paxtablekey:'',
        dateArrive:'',
        dateLeave:'',
        today:'',
        editionStatus:1
    }
  },
  async created () {

    //alert(this.distinctPax(this.passengerSet))
    const itinerary = await this.$apollo.query({
        query: gql`query ($id: ID!) {
            itineraryById(id:$id) {
               id
  			   roundtrip
  			   dateCreated
  			   EditionStatus{
                id
                name
               }
  			itinBookings{
            id
            position
            status{
               id
               name
            }
            pax{
              id
              firstName
              familyName
              passportNumber
              title
            }
            flight{
              id
              dateDeparture
              dateArrive
            }
            ida
            status{
                id
            }
          }
         }
        }`,
        variables: {
          id: this.$route.params.id,
        },fetchPolicy: "network-only"
    })

        this.passengerSet = itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida > 0 && booking.position == 0)
        this.today = itinerary.data.itineraryById.dateCreated
        console.log(this.passengerSet)
        //this.passengerSet = this.passengerSet.pax

    this.itinerary = itinerary.data.itineraryById
    this.value = this.itinerary.code
    this.editionStatus = this.itinerary.EditionStatus.id
    this.idaBookings = this.distinctFlight(itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida > 0)).sort((a,b) => a.position - b.position)
    this.returnBookings = this.distinctFlight(itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida == 0)).sort((a,b) => a.position - b.position)
    console.log(this.idaBookings[this.idaBookings.length-1].flight.dateDeparture)
    this.dateArrive = this.idaBookings[this.idaBookings.length-1].flight.dateArrive
    this.dateLeave = this.returnBookings[0].flight.dateDeparture

  },
  computed: {
},
  methods: {
        goback: function(){if(confirm("Desea ir atras?")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? ")) this.$router.push('/itineraries')},


    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
    displayableTime (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
    distinctPax (array) {
        var distinct = []
        var distinctBooking = []
        console.log(array.length)
        for (var i = 0; i < array.length; i ++)
            if(!distinct.includes(array[i].pax)){
             distinct.push(array[i].pax)
             distinctBooking.push(array[i])
             }
        return distinctBooking
    },
    distinctFlight (array) {
        var distinctFlight = []
        var distinctBooking = []
        for (var i = 0; i < array.length; i ++)
            if(!distinctFlight.includes(array[i].flight.flightNumber)){
             distinctFlight.push(array[i].flight.flightNumber)
             distinctBooking.push(array[i])
             }
        return distinctBooking
    },
    compare(a,b){
        if(a > b)
            return 1;
        else
            return -1;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === "position") {
          if (isDesc) {
            return this.compare(a.position, b.position);
          } else {
            return this.compare(b.position, a.position);
          }
        }
      });
      return items;
    },
   /* generateReport () {
            this.$refs.html2Pdf.generatePdf()
     },*/
     async confirmar() {
        if(confirm("Desea confirmar esta reserva?"))
        {
        this.passengers.splice(this.passengers.length-1, 1);
        this.confirmed=true
        this.updateItin(this.$route.params.id, 2)
        this.paxtablekey++
        }
       },
 /*   imprimir()
    {
      this.$refs.frame.print(this.$refs.toPrint);
    },*/
    exportar() {
         window.location.href="https://traveladmin.dcano14k.com/download_hostal/"+this.$route.params.id
        //this.downloadComponentInPDF(document.getElementById("toExport"))
        //this.saveAsPDF(document.getElementById("toExport"))
     },
   /* async downloadComponentInPDF(Component) {
      var orien = 'l'
      await html2canvas(Component).then((canvas) => {
        const componentWidth = Component.offsetWidth
        const componentHeight = Component.offsetHeight

        orien = componentWidth >= componentHeight ? 'l' : 'p'

        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm'
      })
    const ratio = 216/280
    pdf.internal.pageSize.width = 216
    pdf.internal.pageSize.height = 280
    if(orien == 'l'){
        var heigh = 216*ratio
        pdf.addImage(imgData, 'PNG', 0, 0, 216,heigh)
    }
    else{
        pdf.addImage(imgData, 'PNG', 0, 0, 216, 280)
    }

    pdf.save("Hostal "+this.passengerSet[0].pax.passportNumber+'_'+this.passengerSet[0].pax.familyName+'.pdf')
  })
},
saveAsPDF(Component) {
        html2canvas(Component, {
            onrendered: function (canvas) {
                var wid;
                var hgt;
                var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas
                .height); //image data of canvas
                var hratio = hgt / wid;
                var doc = new jsPDF({
                    orientation: "landscape"
                });
                var width = doc.internal.pageSize.width;
                //var height = doc.internal.pageSize.height;
                var height = width * hratio;
                doc.addImage(img, 'JPEG', width*.025, 20, width*.95, height*.95);
                doc.save('AssetComparison.pdf')
            }
        });
    }*/
},
}
</script>

<style scoped>

</style>
