<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="showFlights"
    >
      <v-toolbar-title>Lista de Vuelos</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addflight"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           Agregar Vuelo
       </v-btn>
   </v-app-bar>
         <v-card v-if="showFlights">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table v-if="showFlights"
            :headers="headers"
            :items="registeredflights"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"


        >
            <template v-slot:item.ocupacion="{ item }">
                <td>
                {{(item.flightBookings
                   .filter(booking => booking.itinerary.isDeleted == 0)
                   .filter(booking => booking.pax.adultType.code != 'INF')
                   .filter(booking => booking.itinerary.EditionStatus.name == 'CONFIRMED'))
                   .length }}
                   /{{item.capacity}}
                   {{item.flightBookings
                   .filter(booking => booking.itinerary.isDeleted == 0 && booking.pax.adultType.code == 'INF')
                   .filter(booking => booking.itinerary.EditionStatus.name == 'CONFIRMED')
                   .length
                 }}
                  Infantes
                </td>
            </template>
            <template v-slot:item.createdBy.username="{ item }">
                <td>{{ item.createdBy.username }}</td>
            </template>
            <template v-slot:item.dateDeparture="{ item }">
                <td>{{ item.dateDeparture.substr(0,10)}}, {{ item.dateDeparture.substr(11,5)}}</td>
            </template>
            <template v-slot:item.airline="{ item }">
                <td><img class="product_image" :src="getUrl(item.airline.iata)" width="80"></td>
            </template>
            <template v-slot:item.terminal.name="{ item }">
                <td>{{tableDisplayTerminal(item)}}</td>
            </template>
            <template v-slot:item.dateArrive="{ item }">
                <td>{{ item.dateArrive.substr(0,10)}}, {{ item.dateArrive.substr(11,5)}}</td>
            </template>

            <template v-slot:item.actions="{ item }">
                     <div>
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showDetailsDialog(item)"
                      >
                      mdi-details
                      </v-icon>
                   <v-icon
                        medium
                        class="mr-2"
                        @click="showEditarDialog(item)"
                      >
                      mdi-pencil
                      </v-icon>
                     <v-icon
                        medium
                        class="mr-2"
                        @click="showDuplicarDialog(item)"
                      >
                      mdi-content-duplicate
                      </v-icon>
                      <v-icon
                        medium
                        @click="Bookings(item)"
                      >
                      mdi-account-group
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
   <v-dialog v-model="dialogDetails" max-width="500px" max-height="800px" v-if="flight">
                <v-card>
                  <v-card-title>Numero de vuelo: {{ flight.airline.iata }} {{ flight.flightNumber }}</v-card-title>
                    <v-card-text>Aerolinea: {{ flight.airline.name }}</v-card-text>
                    <v-card-text>Origen: {{ flight.sourceAirport.name}}, {{ flight.sourceAirport.icaoCode}},  {{ flight.sourceAirport.city}},  {{ flight.sourceAirport.country}}</v-card-text>
                    <v-card-text>Terminal: {{ flight.terminal.name}}</v-card-text>
                    <v-card-text>Fecha de Salida: {{ displayableDate(flight.dateDeparture) }}, {{ (flight.dateDeparture).substr(11,5) }}</v-card-text>
                    <v-card-text>Destino: {{ flight.destinyAirport.name}},  {{ flight.destinyAirport.city}},  {{ flight.destinyAirport.country}}</v-card-text>
                    <v-card-text>Fecha de Llegada: {{ displayableDate(flight.dateArrive) }}, {{ (flight.dateArrive).substr(11,5) }}</v-card-text>
                    <v-card-text>Capacidad: {{ flight.capacity }}</v-card-text>
                    <v-card-text>Estado: {{ flightEditionEstatus(flight) }}</v-card-text>
                    <v-card-text>Comentario: {{ flight.remarks }}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>
                    <v-btn color="primary" text @click="Bookings(flight)">Manifiesto</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogEditar" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Edicion</v-card-title>
                  <v-card-text>Desea Editar el Vuelo?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogEditar = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="editItem()">Editar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogDuplicar" max-width="500px">
                <v-card>
                  <v-card-title>Alerta </v-card-title>
                  <v-card-text>Desea Duplicar este Vuelo?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDuplicar = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="doubleItem()">Duplicar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>
      <bookingByFlightList v-if="showAllBookings" :bookings="allFlightbookings" :flight = "flightInfo" @hide = "hideBookings" />
      <bookingByFlightList v-if="showBookings" :bookings="flightbookings" :flight = "flightInfo" @hide = "hideBookings" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { NEW_FLIGHT_LIST } from '../constants/graphql'
import { OLD_FLIGHT_LIST} from '../constants/graphql'
import bookingByFlightList from '@/components/bookingByFlightList'
export default {
  name: 'FlightList',
  data() {
      return {
            search: '',
            headers: [
            { text: 'Aerolinea', value: 'airline' },
          { text: 'Numero de vuelo', value: 'flightNumber' },
          { text: 'Ocupacion', value: 'ocupacion' },
          { text: 'Origen', value: 'sourceAirport.name' },
          { text: 'Terminal', value: 'terminal.name' },
          {text: 'Hora de Salida', value: 'dateDeparture'},
          { text: 'Destino', value: 'destinyAirport.name' },
          {text: 'Hora de Llegada', value: 'dateArrive'},
          {text: 'Action', value: 'actions'},
        ],
        dialogEditar: '',
        dialogDuplicar:'',
        dialogDetails:'',
        flight: null,
        showBookings:false,
        showAllBookings:false,
        showFlights: true,
        flightbookings:[],
        allFlightbookings:[],
        flightInfo:'',
        allFlightList:[],
        loading:false,
     }
  },
  components: {
        bookingByFlightList,
  },
  props: {
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    registeredflights () {
      return this.allFlightList

      //return this.allFlightList.filter(flights => flights.is_editable)
    },

  },
  async mounted () {
     this.loadFlightList()
    },
  methods: {
    hideBookings(){
        this.showBookings = false
        this.showFlights = true
    },
    getUrl(code){
      if(code == "T9")
         return "https://turpialairlines.com/img/logo-turpial.png"
      if(code == "S6")
         return "https://airhex.com/images/airline-logos/sunrise-airways.png"
      if(code == "Y2")
         return "https://aircentury.com/wp-content/uploads/2023/09/logotipo-air-century-1.svg"
      if(code=="8W")
         return "https://flyallways.com/wp-content/uploads/2022/09/FAW-logo-1024x359-1.png"
      if(code == "DO")
         return "https://skyhighdominicana.com/wp-content/uploads/2023/04/SKYhigh-Logo-SKYhigh.svg"
      if(code == "WN")
         return "https://logos-world.net/wp-content/uploads/2020/10/Southwest-Airlines-Logo-120x67.png"
      if(code=="VB")
        return "https://media.staticontent.com/media/pictures/b7ee1c9a-49b9-4724-8c0e-d4ae07e938e7"
      else
        return "https://s1.apideeplink.com/images/airlines/" + code + ".png"
    },
    async Bookings(item){
        const Books = await this.$apollo.query({
        query: gql`query ($flight: Int!) {
            bookingsByFlight(flight:$flight){
                id
                status{
                id
                name
                }
                createdBy{
                    id
                    username
                }
                flight{
                  id
                  flightNumber
                  dateDeparture
                  airline{
                        id
                        iata
                      }
                 sourceAirport{
                        id
                        name
                        city
                        country
                        icaoCode
                      }
                destinyAirport{
                        id
                        name
                        city
                        country
                        icaoCode
                      }

            }
                pax{
                  id
                  passportNumber
                  familyName
                  firstName
                  dateBorn
                  dateExpired
                  dateCreated
                  dateModified
                  isEditable
                  remarks
                  email
                  adultType{
                    id
                    name
                    code
                  }
                  civil{
                    id
                    name
                    code
                  }
                  passportType{
                    id
                    name
                    code
                  }
                  genero{
                    id
                    name
                    code
                  }
                  nacionality{
                    id
                    nationality
                  }
                  issueCountry{
                    id
                    enShortName
                  }
                  housePhone
                  phoneNumber
                  dateValid
                  createdBy {
                    id
                    firstName
                    username
                    lastName
                  }

                }
                itinerary{
                  id
                  code
                  isDeleted
                  remarks
                  visa
                  EditionStatus{
                   id
                   name
                  }
                }
        }
    }`,
    variables: {
          flight: item.id,
        },
    })
    this.flightbookings = Books.data.bookingsByFlight


    this.flightInfo = item.airline.iata+"-"+item.flightNumber+"_"+item.dateDeparture.substr(0,10)
    this.showBookings = true
    this.showFlights = false
    },
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full',timeStyle: 'long' }).format(new Date(date))
    },
    displayableTime (date) {
      return new Intl.DateTimeFormat('en-US', { timeStyle: 'long' }).format(new Date(date))
    },
    tableDisplayTerminal(item){
            return item.terminal.name
    },
   selectItem: function(item) {
      if (confirm('Seleccionar este pasajero? ' + item.firstName +" "+item.familyName)) {
            //this.$emit('paqueteselect', subscr.id)
            this.$router.push('/flight/'+ item.passportNumber)
       }
    },

        addpkg: function() {
        },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

       async loadFlightList(){
        console.log("LOading flights")
         this.loading = true
          var newFligts
          await this.$apollo.query({
                query: NEW_FLIGHT_LIST,
                fetchPolicy: "network-only",
                }).then((data)=>{
                console.log("flights loaded")
                newFligts = data.data.allNewFlights

                })
          await this.$apollo.query({
                query: OLD_FLIGHT_LIST,
                fetchPolicy: "network-only",
                }).then((data)=>{
                console.log("flights loaded")
                this.allFlightList = newFligts.concat(data.data.allOldFlights)
                this.loading = false
                })
       },

        showDetailsDialog(item){
            this.flight = item
            this.dialogDetails = !this.dialogDetails
        },

        showEditarDialog(item) {
            this.flight = item
            this.dialogEditar = !this.dialogEditar
        },

        showDuplicarDialog(item) {
            this.flight = item
            this.dialogDuplicar = !this.dialogDuplicar
        },
        flightEditionEstatus(item){
             if(item.is_editable) {return "Borrador"} else {return "Cerrado"}
        },

        detalles: function(){
            this.$router.push('/flight/'+ this.flight.id)
            this.dialogDuplicar = false
          },

        editItem() {
            this.$router.push('/editflight/'+ this.flight.id)
            this.dialogDelete = false
        },

        doubleItem() {
            this.$router.push('/newflight/'+ this.flight.id)
            this.dialogDelete = false
        },

        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
