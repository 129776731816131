<template>
  <div>
      <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
        <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Reporte de Ventas</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>

   </v-app-bar>
      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="itineraryReport"
            :items-per-page="50"
            class="elevation-1"
            :loading="loading"


        >

   </v-data-table>
 </v-card>
 </div>
</template>

<script>
import { ITINERARY_REPORT } from '../constants/graphql'
export default {
  name: 'ItineraryReport',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Year/Month', value: 'month', sortable: false },
          { text: 'Itinerarios Confirmados', value: 'monthCount' },
          { text: 'Pasajeros Confirmados', value: 'paxCount' },
          { text: 'Tarifa Base', value: 'tarifaSum' },
          { text: 'Comision', value: 'comisionSum' },
        ],
        itineraryReport:[],
        loading:false,

     }
  },
  components: {
  },

  async created (){

  },
  async mounted () {
     this.loadItineraryReport()
    },
  methods: {
   async loadItineraryReport(){
     this.loading = true
     await this.$apollo.query({
            query: ITINERARY_REPORT,
            fetchPolicy: "network-only",
            })
            .then((data)=>{
             this.itineraryReport = data.data.itinerarySumByMonth
             this.loading = false
            })
   },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},





    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
