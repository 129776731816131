<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
        <v-dialog v-model="saving" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-layout row wrap>
      <v-flex xs12 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Pasajero</v-toolbar-title>
          </v-toolbar>
            <v-card-text>
              <v-container>
                 <v-form lazy-validation v-model="valid" ref="form">
                    <v-layout row>
                        <v-flex xs12 sm6 lg4 align-start justify-center>
                           <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.id')}}
                           </label>
                           <v-text-field
                            solo
                            autocomplete="off"
                            id="ident"
                            v-model="passport"
                            @input="passport = passport.toUpperCase()"
                            name="ident"
                            :rules="[rules.required]"
                            hint = "This Field is Required"
                            required
                            v-bind:placeholder="$t('lang.persons.id')">
                           </v-text-field>
                            </v-flex>
                        <v-flex xs12 sm6 lg4 align-center justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.id_type')}}
                            </label>
                                <v-select class="form-control" id="pptype"
                                         @change =  "searchPax"
                                        v-model="passport_type"
                                        :rules="[rules.required]"
                                        hint = "This Field is Required"
                                        required
                                        name="pptypeselect" :items="allPassportTypes" item-value = "id" item-text="name" solo></v-select>
                                </v-flex>
                        <v-flex xs12 sm6 lg4 align-end justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.adultotype')}}
                            </label>
                                <v-select class="form-control" id="adult"
                                          @change =  "searchPax"
                                          v-model="adult_type"
                                            :rules="[rules.required]"
                                            hint = "This Field is Required"
                                            required
                                          name="adultselect" :items="allAdultTypes" item-value = "id" item-text="name" solo>

                                </v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12 sm6 align-start justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.first_name')}}
                           </label>
                       <v-text-field
                            @input="first_name = first_name.toUpperCase()"
                            solo
                            id="first_name"
                            v-model="first_name"
                                        :rules="[rules.required]"
                                        hint = "This Field is Required"
                                        required
                            name="first_name">
                       </v-text-field>
                            </v-flex>
                        <v-flex xs12 sm6 align-end justify-center>
                        <label class="col-sm-10 control-label ">
                          {{$t('lang.persons.last_name')}}
                       </label>
                         <v-text-field

                            solo
                            id="last_name"
                            v-model="last_name"
                            @input="last_name = last_name.toUpperCase()"
                                        :rules="[rules.required]"
                                        hint = "This Field is Required"
                                        required
                            name= "last_name">
                         </v-text-field>
                            </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12 sm6 align-start justify-center>
                           <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.EstadoCivil')}}
                           </label>
                                <v-select class="form-control" id="civil"
                                v-model="civil"
                                         :rules="[rules.required]"
                                        hint = "This Field is Required"
                                        required
                                name="civilselect" :items="allCivils" item-value = "id" item-text="name" solo></v-select>
                            </v-flex>
                        <v-flex xs12 sm6 align-end justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.genero')}}
                            </label>
                                <v-select class="form-control" id="genero"
                                v-model="genero"
                                          :rules="[rules.required]"
                                        hint = "This Field is Required"
                                        required
                                name="generoselect" :items="allGeneros" item-value = "id" item-text="name" solo></v-select>
                            </v-flex>
                    </v-layout>
                    <v-layout row pt-10>
                        <v-flex xs12 sm6 lg4 align-start justify-center>
                           <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.born_date')}}
                           </label>
                        <v-menu
                            ref="born_menu"
                            v-model="born_menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="born_date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="bornpicker"
                              v-model="born_date"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1920-01-01"
                              @change="born_date_save"
                            ></v-date-picker>
                          </v-menu>
                         </v-flex>
                        <v-flex xs12 sm6 lg4 align-center justify-center>
                        <label class="col-sm-10 control-label ">
                          {{$t('lang.persons.id_issue_date')}}
                        </label>
                    <v-menu
                        ref="issue_menu"
                        v-model="issue_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="issue_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="issuepicker"
                          v-model="issue_date"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1930-01-01"
                          @change="issue_date_save"
                        ></v-date-picker>
                      </v-menu>
                     </v-flex>
                        <v-flex xs12 sm6 lg4 align-end justify-center>
                        <label class="col-sm-10 control-label ">
                          {{$t('lang.persons.id_expire_date')}}
                        </label>
                    <v-menu
                        ref="expire_menu"
                        v-model="expire_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="expire_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="expirepicker"
                          v-model="expire_date"
                          :min="new Date().toISOString().substr(0, 10)"
                          @change="expire_date_save"
                        ></v-date-picker>
                      </v-menu>
                     </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12 sm6 align-start justify-center>
                        <label class="col-sm-10 control-label ">
                          {{$t('lang.pais.issue')}}
                        </label>
                            <v-select class="form-control" id="issueCountry"
                            v-model="issue_country"
                            v-bind:placeholder="$t('lang.address.localidadPH')"
                            name="issuecountries" :items="allCountries" item-value = "id" item-text="enShortName" solo></v-select>
                     </v-flex>
                        <v-flex xs12 sm6 align-end justify-center>
                        <label class="col-sm-10 control-label ">
                          {{$t('lang.pais.nacionalidad')}}
                        </label>
                            <v-select class="form-control" id="nacionalidad"
                            v-model="nacionality"
                            v-bind:placeholder="$t('lang.address.nacionalidad')"
                            name="nacionalidad" :items="allCountries" item-value = "id" item-text="nationality" solo></v-select>
                     </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12 sm4 align-start justify-center>
                           <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.email')}}
                           </label>
                           <v-text-field
                            solo
                            autocomplete="off"
                            id="email"
                            v-model="email"
                            name="email"
                            v-bind:placeholder="$t('lang.persons.email')">
                           </v-text-field>
                            </v-flex>
                        <v-flex xs12 sm4 align-center justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.movil')}}
                            </label>
                            <v-text-field
                            solo
                            autocomplete="off"
                            id="movil"
                            v-model="movil"
                            name="movil"
                            v-bind:placeholder="$t('lang.persons.movil')">
                           </v-text-field>
                          </v-flex>
                        <v-flex xs12 sm4 align-end justify-center>
                            <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.phone')}}
                            </label>
                            <v-text-field
                            solo
                            autocomplete="off"
                            id="phone"
                            v-model="phone"
                            name="phone"
                            v-bind:placeholder="$t('lang.persons.phone')">
                           </v-text-field>
                          </v-flex>
                   </v-layout>
                    <v-layout row>
                        <v-flex xs12 sm12 align-center justify-center>
                           <label class="col-sm-10 control-label ">
                              {{$t('lang.persons.photo')}}
                           </label>

                        <!--    <v-btn color="primary" :loading="isSelecting" @click="handleFileImport" >
                                {{$t('lang.persons.phbutton')}}
                            </v-btn>
                            <input ref="uploader" class="d-none" type="file" @change="onFileChanged" > -->
                        <v-file-input v-model="image"
                                      type="file"
                                      class="input"
                                      label="Subir Pasaporte"
                                      hint="Agregar una imagen de su pasaporte"
                                      outlined
                                      dense
                                      @change="onFileChange">
                        </v-file-input>
                            <v-img
                                max-height="500"
                                max-width="500"
                            :src="imageUrl" />

                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12  sm12 align-center justify-center>
                            <label class="col-sm-6 control-label ">
                                {{$t('lang.persons.remarks')}}
                            </label>
                            <v-textarea
                                solo
                                id="remarks"
                                v-model="remarks"
                                name="remarks">
                            </v-textarea>
                        </v-flex>
                    </v-layout>
                    <v-btn color="primary" @click= "submitPaxForm" ><v-icon medium class="mr-2">
                    mdi-save
               </v-icon>
                        {{$t('lang.persons.sbutton')}}
                    </v-btn>
                 </v-form>
          </v-container>

        </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_PAX_MUTATION } from '../constants/graphql'
import { UPLOAD_PP } from '../constants/graphql'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'CreatePax',
    setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
    data() {
        return {
            titulo:"AGREGAR",
                created_by: (JSON.parse(this.userStore.getUser)).username,
                issue_menu: false,
                passport: '',
                first_name: '',
                last_name: '',
                born_date: null,
                issue_country: 59,
                issue_date: null,
                expire_date: null,
                expire_menu: false,
                remarks: '',
                title: '',
                email: '@',
                phone: '+',
                adult_type: '',
                civil: '',
                passport_type: '',
                genero: '',
                nacionality: 59,
                movil: '+',
                born_menu: false,
                titles: ['CHILD','MISTER','MISSIS','INFANT'],
                // isSelecting: false,
                // selectedFile: null,
                image: null,
                //to save image url
                imageUrl: "",
                mypp:'',
                valid:false,
                saving:false,
                loading:false,
                searching:false,
                rules: {
                  required: value => !!value || 'Required.',

                },
    };

            },
    apollo: {
    allCountries: gql`query {
      allCountries {
        id
        enShortName
        alpha3Code
        nationality
      }
    }`,
    allGeneros: gql`query {
      allGeneros {
        id
        name
        code
      }
    }`,
    allAdultTypes: gql`query {
      allAdultTypes {
        id
        name
        code
      }
    }`,

    allCivils: gql`query {
      allCivils {
        id
        name
        code
      }
    }`,
    allPassportTypes: gql`query {
      allPassportTypes {
        id
        name
        code
      }
    }`,

  },
 /* watch: {
      born_menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      expire_menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      issue_menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },*/
  methods:{
      createImage(file) {
            const reader = new FileReader()

            reader.onload = e => {
                this.imageUrl = e.target.result
            }
            reader.readAsDataURL(file)
      },
      onFileChange(file) {
                if (!file) {
                 alert("No PP Image")
                }
                this.mypp = file
                this.createImage(file)
       },
       async searchPax(){
        if(!this.searching){
         this.searching = true
         this.loading = true
         if(this.passport!=''){
         await this.$apollo.query({
            query: gql`query ($pp: String!) {
                paxByPp(pp:$pp){
                    id
                    passportNumber
                    firstName
                    familyName
                    createdBy{
                     id
                     username
                    }
                    paxBookings{
                      id
                      position
                      ida
                      itinerary{
                        id
                        isDeleted
                      }
                    }

                }
            }`,
            variables: {
              pp: this.passport
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data)
               alert("Este pasajero ya esta registrado:"+data.data.paxByPp.passportNumber)
               this.loading = false
               this.searching = false
               this.paxFound = true
           }).catch((error) => {
              // Error
              this.loading = false
              this.searching = false
              //alert("Pasajero no encontrado")
              //this.btnStatus=false
              this.paxFound = false
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         }
     }
     },
    async uploadPp(){
      let file = this.image
      let pp = this.passport
      this.saving = true
      await this.$apollo.mutate({
          mutation: UPLOAD_PP,
          variables: {
           pp,
           file
            },
        })
        .then(()=>{
           console.log("File Uploaded")
        })
        .catch((error) => {
              // Error
              this.saving = false
              alert("Este es el Error: "+error)
              // We restore the initial user input
         })
    },
    expire_date_save (expire_date) {
        this.$refs.expire_menu.save(expire_date)
      },
    born_date_save (born_date) {
        this.$refs.born_menu.save(born_date)
      },
    issue_date_save (issue_date) {
        this.$refs.issue_menu.save(issue_date)
      },

    submitPaxForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$refs.form.resetValidation(); // Note that v-form also has another function called resetValidation(), so after we empty our fields, it won't show the validation errors again.

        this.addPax()

      }
      else{
         alert("EL Formulario tiene errores revise.")
      }
    },
    addPax(){
        const { passport,
        first_name,
        last_name,
        created_by,
        issue_date,
        expire_date,
        born_date,
        remarks,
        issue_country,
        title,
        adult_type,
        civil,
        passport_type,
        genero,
        nacionality,
        movil,
        phone,
        email,
        } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_PAX_MUTATION,
        variables: {
            passport,
            first_name,
            last_name,
            created_by,
            issue_date,
            expire_date,
            born_date,
            remarks,
            issue_country,
            title,
            adult_type,
            civil,
            passport_type,
            genero,
            nacionality,
            movil,
            phone,
            email
        }
        }).then(()=>{
            if(this.image!=null){
               this.uploadPp()
             .then(()=>{
             this.$router
            .push('/paxs')

             })
            }
            else{
               this.$router.push('/paxs')
             }
         })
         .catch(() => {
              // Error
              alert("Error Saving Pasajero. Revise si ya existe")
              // We restore the initial user input
         })


    },
  },
 }
</script>