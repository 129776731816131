<template>
  <div class="col-sm-5" v-if="pax">
    <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title > Detalles del Pasajero</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" ><v-icon dark right>mdi-account-convert</v-icon></v-btn>
     </v-app-bar>
      <v-card>

    <v-container>

        <v-layout row>
                <div class="headline mt-3">Nombre: {{ pax.title }}. {{ pax.firstName }} {{ pax.familyName }}</div>
        </v-layout>
        <v-layout row>
                <div class="headline mt-3">Pasaporte: {{ pax.passportNumber }}</div>
         </v-layout>
        <v-layout row>
                <div class="headline mt-3">Fecha de emision: {{ displayableDate(pax.dateExpired) }}</div>
        </v-layout>
        <v-layout row>
                <div class="headline mt-3">Fecha de nacimiento: {{ displayableDate(pax.dateBorn) }}</div>
        </v-layout>
        <v-layout row>
                <div class="headline mt-3">Creado: {{ displayableDate(pax.dateCreated) }}</div>
        </v-layout>
        <v-layout row>
                <div class="headline mt-3">Creado Por: {{ pax.createdBy.username }}</div>
        </v-layout>
        <v-layout row>
            <v-flex>
                <v-img
                                max-height="500"
                                max-width="500"
                            :src="imageUrl" />
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex>
                <div class="headline mt-3">Comentario: {{ pax.remarks }}</div>
            </v-flex>


        </v-layout>


    </v-container>
   </v-card>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'PaxDetails',
  components: {

  },
  data () {
    return {
      pax: null,
    }
  },
  async created () {
    const pax = await this.$apollo.query({
        query: gql`query ($i: String!) {
          paxByPassport(id: $id) {
          passportNumber
          familyName
          firstName
          dateBorn
          dateExpired
          dateCreated
          dateModified
          isEditable
          title
          remarks

          createdBy {
            firstName
            username
            lastName
          }
          }
        }`,
        variables: {
          id: this.$route.params.id,
        },
    })
    this.pax = pax.data.paxByPassport
  },
  methods: {
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
    displayableTime (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
  },
}
</script>

<style>
</style>
