import Vue from 'vue'
import VueRouter from 'vue-router'
import pinia from "./pinia.js";
import { useUserStore } from "@/stores/user";


import Post from '@/components/Post'
import Author from '@/components/Author'
import PostsByTag from '@/components/PostsByTag'
import AllItineraries from '@/components/AllItineraries'
import ItineraryList from '@/components/ItineraryList'
import AllPassengers from '@/components/AllPassengers'
import CreatePax from '@/components/CreatePax'
import PaxDetails from '@/components/PaxDetails'
import EditarPax from '@/components/EditarPax'
import AllFlights from '@/components/AllFlights'
import AllNewFlights from '@/components/AllNewFlights'
import CreateFlight from '@/components/CreateFlight'
import EditFlight from '@/components/EditFlight'
import ItineraryDetails from '@/components/itineraryDetails.crystal'
import HostalBooking from '@/components/HostalBooking'
import FastItin from '@/components/FastItin'
import CreateRuta from '@/components/CreateRuta'
import AllRutas from '@/components/AllRutas'
import AllBags from '@/components/AllBags'
import AllHoteles from '@/components/AllHoteles'
import AllAirports from '@/components/AllAirports'
import AllAirlines from '@/components/AllAirlines'
import AllReservas from '@/components/AllReservas'
import subirImagen from '@/components/subirImagen'
import AllTerminals from '@/components/AllTerminals'
import Amadeus from '@/components/amadeus'
import SellerFastItin from '@/components/SellerFastItin'
import AllWaitingList from '@/components/AllWaitingList'
import AllBlackList from '@/components/AllBlackList'
import AllSellerList from '@/components/AllSellerList'
import ItineraryReport from '@/components/ItineraryReport'

Vue.use(VueRouter)

const routes = [
  { path: '/author/:username', component: Author },
  { path: '/post/:slug', component: Post },
  { path: '/tag/:tag', component: PostsByTag, meta: { requiresAuth: true } },
  { path: '/itineraries',component: AllItineraries, meta: { requiresAuth: true } },
  { path: '/allItineraries',component: ItineraryList, meta: { requiresAuth: true } },
  { path: '/paxs', component: AllPassengers, meta: { requiresAuth: true } },
  { path: '/newpax', component: CreatePax, meta: { requiresAuth: true } },
  { path: '/pax/:id', component: PaxDetails, meta: { requiresAuth: true } },
  { path: '/editpax/:id', component: EditarPax, meta: { requiresAuth: true } },
  { path: '/flights', component: AllFlights, meta: { requiresAuth: true } },
  { path: '/newFlights', component: AllNewFlights, meta: { requiresAuth: true }  },
  { path: '/newflight', component: CreateFlight, meta: { requiresAuth: true } },
  { path: '/newflight/:id', component: CreateFlight, meta: { requiresAuth: true } },
  { path: '/editflight/:id', component: EditFlight, meta: { requiresAuth: true } },
  { path: '/subirpp', component: subirImagen},
  { path: '/amadeus', component: Amadeus},
  { path: '/blacklist', component: AllBlackList },
  { path: '/waitinglist', component: AllWaitingList, meta: { requiresAuth: true }},
  { path: '/sellerlist', component: AllSellerList, meta: { requiresAuth: true }},
  { path: '/itineraryReport', component: ItineraryReport, meta: { requiresAuth: true }},
  { path: '/itinerary/:id', name: 'itinerary',component: ItineraryDetails,
   meta: {
    hideNavbar: true,
    requiresAuth: true
  }
  },
    { path: '/hostalbooking/:id', component: HostalBooking,
   meta: {
    hideNavbar: true,
    requiresAuth: true
  }
  },
  { path: '/newItinerary/:id', component: FastItin, meta: { requiresAuth: true } },
  { path: '/fastItin', component: FastItin, meta: { requiresAuth: true } },
  { path: '/sellerItin', component: SellerFastItin, meta: { requiresAuth: true } },
  { path: '/newRuta', component: CreateRuta, meta: { requiresAuth: true } },
  { path: '/allRutas', component: AllRutas, meta: { requiresAuth: true }  },
  { path: '/allBags', component: AllBags, meta: { requiresAuth: true }  },
  { path: '/allHotels', component: AllHoteles , meta: { requiresAuth: true } },
  { path: '/allAirports', component: AllAirports, meta: { requiresAuth: true }  },
  { path: '/allAirlines', component: AllAirlines , meta: { requiresAuth: true } },
  { path: '/allReservas', component: AllReservas, meta: { requiresAuth: true }  },
  { path: '/allTerminals', component: AllTerminals, meta: { requiresAuth: true }  },
  /*{
    path: '/',
    name: 'main',
    meta: { title: 'Home page' },
    component: SingIn
  },*/
 {
   path: '/login',
   name: 'Login',
   // route level code-splitting
   // this generates a separate chunk (login.[hash].js) for this route
   // which is lazy-loaded when the route is visited.
   component: () => import(/* webpackChunkName: "signin" */ '@/components/SingIn')
 },
{
   path: '*',
   redirect: 'login'
 }

]

const router = new VueRouter({
  routes: routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
    // Check if the user is logged i
  const userStore = useUserStore(pinia)
  const isUserLoggedIn = userStore.getAuthStatus
  console.log("Router Is Logg in>>"+isUserLoggedIn)
  if(userStore.getUser != null)
   console.log(">>"+userStore.getUser.username)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isUserLoggedIn == 'false') {
      console.log('User Not Logged In')
      userStore.removeUser()
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
