<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
    >

       <v-btn
       color="primary"
       @click= "hideThis"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-arrow-left
       </v-icon>
           Atras
       </v-btn>
      <v-toolbar-title> | {{titleLabel}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 pt-10>
                    <v-select
                      v-model="Filtro"
                      label="Filtrar"
                      :items="['Confirmados', 'Visados', 'Todos']"
                    ></v-select>
      </v-flex>

       <v-spacer></v-spacer>


   <download-excel v-if = "Filtro=='Confirmados'" :data="registeredPaxs" :export-fields="xlsFields" :name ="xlsName" stringifyLongNum:true>
        <v-btn
       color="primary"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-file-excel-box
       </v-icon>
           Exportar PNL
       </v-btn>

</download-excel>
   </v-app-bar>

         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
             :headers="headers"
            :items="registeredPaxs"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            >
            <template v-slot:item.itinerary.code="{ item }">
                <router-link :to="{ name: 'itinerary', params: { id: item.itinerary.id } }">
                    <td >{{item.itinerary.code}} </td>
                </router-link>
            </template>
            <template v-slot:item.dateBorn="{ item }">
                <td v-if="item.pax.dateBorn != null">{{item.pax.dateBorn.substr(0,10)}} </td>
            </template>
            <template v-slot:item.dateIssue="{ item }">
                <td v-if="item.pax.dateExpired != null">{{item.pax.dateExpired.substr(0,10)}}</td>
            </template>
            <template v-slot:item.dateValid="{ item }">
                <td v-if="item.pax.dateValid != null">{{item.pax.dateValid.substr(0,10)}}</td>
            </template>

            <template v-slot:item.createdBy.username="{ item }">
                <td>{{ item.createdBy.username }}</td>
            </template>


            <template v-slot:item.actions="{ item }">
                     <div>
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showDetailsDialog(item)"
                      >
                      mdi-details
                      </v-icon>
                      <v-icon
                        medium
                        @click="showEditDialog(item)"
                      >
                      mdi-pencil
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
   <v-dialog v-model="showEdit">
    <EditPax :pax="paxToEdit" @hide = "hideEdit" v-bind:key="update"/>
   </v-dialog>
   <v-dialog v-model="dialogDetails" max-width="500px" max-height="700px" v-if="pax">
                <v-card>
                  <v-card-title>Detalles del Pasajero {{ pax.firstName }} {{ pax.familyName }}</v-card-title>
                    <v-card-text>Pasaporte: {{ pax.passportNumber }}</v-card-text>
                    <v-card-text>Fecha de Expedicion: {{ displayableDate(pax.dateExpired) }}</v-card-text>
                    <v-card-text>Fecha de nacimiento: {{ displayableDate(pax.dateBorn) }}</v-card-text>
                    <v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>
                    <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                    <v-card-text>Estado: {{ pax.EditionEstatus }}</v-card-text>
                    <v-img
                                max-height="500"
                                max-width="500"
                            :src="imageUrl"

                    />
                    <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="downloadPp()" v-if = "imageUrl">Descargar</v-btn>
                    <v-btn color="primary" text @click="dialogDetails = hidePaxDetails()">Aceptar</v-btn>
                    <v-btn color="primary" text @click="detalles()" v-if = "pax.is_editable">Detalles"</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogEditar" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Edicion</v-card-title>
                  <v-card-text>Desea Editar el Pasajero?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogEditar = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="editItem()">Editar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import EditPax from '@/components/EditPax'
import {PAX_IMG } from '../constants/graphql'
export default {
  name: 'bookingByFlightList',
  data() {
      return {
            search: '',
            headers: [
            {text: 'Itinerario', value: 'itinerary.code' },
           { text: 'Civility', value: 'pax.civil.name' },
           { text: 'Gender', value: 'pax.genero.name' },
           { text: 'First name', value: 'pax.firstName' },
           { text: 'Last name', value: 'pax.familyName' },
           { text: 'Nacionalidad', value: 'pax.nacionality.nationality' },
           { text: 'Date of Birth', value: 'dateBorn' },
          { text: 'Document Number', value: 'pax.passportNumber' },
          { text: 'Document issuance date', value: 'dateIssue' },
          { text: 'Document expiration date', value: 'dateValid' },
          { text: 'Document issuance country', value: 'pax.issueCountry.enShortName' },
          { text: 'Email', value: 'pax.email' },
          { text: 'Home phone number', value: 'pax.housePhone' },
          {text: 'Action', value: 'actions'},
        ],
        dialogDelete: '',
        dialogDuplicar:'',
        dialogEditar:'',
        dialogDetails:'',
        showAllBookings: false,
        showOnlyVisa: false,
        allowPNL:true,
        btnLabel:'Mostrar Todos',
        btnVisaLabel: "Mostrar Visados",
        titleLabel : "Lista de los Pasajeros del vuelo",
        pax: null,
        showEdit: false,
        paxToEdit:'',
        imageUrl:'',
        Filtro:"Confirmados",
        xlsName:"pnl_"+this.flight+".xls",
        xlsFields: {
          "Passenger Type": "pax.adultType.name",
          Civility: "pax.civil.name",
          Gender: "pax.genero.name",
          "First name": "pax.firstName",
          "Last name": "pax.familyName",
          Nacionalidad: "pax.nacionality.nationality",
          "Date of Birth": {
            field: "pax.dateBorn",
            callback: (value) => {
              return `${value.substr(0,10)}`;
            },
          },
          "Document Number":"pax.passportNumber",
          "Document issuance date": {
            field: "pax.dateExpired",
            callback: (value) => {
              return `${value.substr(0,10)}`;
            },
          },
          "Document expiration date": {
            field: "pax.dateValid",
            callback: (value) => {
              return `${value.substr(0,10)}`;
            },
          },
          "Document issuance country": "pax.issueCountry.enShortName",
          Email: "pax.email",
          "Home phone number": "pax.housePhone",
          "Mobile phone number": "pax.phoneNumber",
          "Remark": {
            field: "itinerary.remarks",
            callback: (value) => {
              return `${value.split(":").pop()}`;
            },
          },
          },
     }
  },
  components: {
        EditPax,
  },
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    flight: {
        type: String,
    },
  },
  computed: {
    registeredPaxs () {
       if(this.Filtro=="Todos"){
          return this.bookings
            .filter(item => item.itinerary.isDeleted == 0)
            .filter(item => item.itinerary.EditionStatus.name == 'CONFIRMED')

        }
       else if(this.Filtro == "Visados"){

       return this.bookings
              .filter(item => item.itinerary.isDeleted == 0)
              .filter(item => item.itinerary.EditionStatus.name == 'CONFIRMED')
              .filter(item => item.itinerary.visa)
        }
       else {

        return this.bookings.filter(item => item.itinerary.isDeleted == 0)
                            .filter(item => item.status.id == 1)
                            .filter(item => item.itinerary.EditionStatus.name == 'CONFIRMED')
                            .filter(item => !item.itinerary.visa)

       }
      //return this.paxs.filter(paxs => paxs.is_editable)
    },

  },
  methods: {
    hideThis(){
        this.$emit('hide')
    },

    showAll(){
      this.showAllBookings = !this.showAllBookings
      if(this.showAllBookings){
          this.allowPNL = false
          this.btnLabel = "Mostrar Confirmados"
          this.titleLabel = "Lista de Todos los Pasajeros"
          }
      else{
          this.allowPNL = true
          this.btnLabel = "Mostrar Todos"
          this.titleLabel = "Lista de los Pasajeros Confirmados"
          }
    },

    showVisados(){
      this.showOnlyVisa = !this.showOnlyVisa

      if(!this.showOnlyVisa){
          this.allowPNL = true
          this.btnVisaLabel = "Mostrar Visados"
          this.titleLabel = "Lista de los Pasajeros Confirmados"
          }
      else{
          this.allowPNL = false
          this.btnVisaLabel = "Mostrar Confirmados"
          this.titleLabel = "Lista de los Pasajeros para Visado"
          }
    },
    displayableDate (date) {
     if(date != null)
       return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
      else return "Vacio"
    },
       selectItem: function(item) {
          if (confirm('Seleccionar este pasajero? ' + item.firstName +" "+item.familyName)) {
                //this.$emit('paqueteselect', subscr.id)
                this.$router.push('/pax/'+ item.passportNumber)
           }
        },


        goback: function(){},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addPax(){if(confirm("Desea agregar un pasajero.")) this.$router.push('/newPax')},

        showEditDialog(item) {
            this.pax = item.pax
            this.dialogEditar = !this.dialogEditar
        },

        async showDetailsDialog(item) {
            this.pax = item.pax
            await this.$apollo.query({
                query: PAX_IMG,
                fetchPolicy: "network-only",
                variables:{
                  ppnumber: item.pax.passportNumber
                }
                }).then((data)=>{
                this.imageUrl = "https://traveladmin.dcano14k.com/media/" + data.data.passportImageByPp.logo
                console.log(this.imageUrl)
                })
                .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
            console.log(item)
            this.dialogDetails = !this.dialogDetails
        },
        hidePaxDetails(){
           this.dialogDetails = false
           this.imageUrl = null
        },

        downloadPp(){
           if(confirm("Desea descargar la imagen del pasaporte?"))
               {
                window.open(this.imageUrl, '_blank')
               }
        },
        paxEditionEstatus(item){
             if(item.is_editable) {return "Borrador"} else {return "Cerrado"}
        },

        detalles: function(){
            this.$router.push('/pax/'+ this.pax.passportNumber)
            this.dialogDuplicar = false
          },

        editItem() {
             this.paxToEdit = this.pax
             this.showEdit = true
             this.dialogEditar = false
            /*
                this.$router.push('/editpax/'+ this.pax.id)
                this.dialogEditar = false
            */
        },
        hideEdit()
             {
               this.showEdit = false
               this.paxId = 0
             },
        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
