<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title>Itinerarios</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addItineario"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           Agregar Itinerario
       </v-btn>
   </v-app-bar>
         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="filterItins"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :key="tableKey"
            :loading="loading"

        >
       <template v-slot:item.code="{ item }" >
               <v-chip :color="getReubColor(item.reubicado)">
                <td @click="showDetails(item)"> {{item.code}}</td>
           </v-chip>
       </template>
       <template v-slot:item.pasajeros="{ item }">
                <td>{{item.paxs[0]}} {{item.code}}</td>
       </template>
       <template v-slot:item.createdAt="{ item }">
                <td>{{ item.createdAt.substr(11,5)}} del {{ item.createdAt.substr(0,10)}} LT</td>
       </template>
      <template v-slot:item.EditionStatus="{ item }">
              <v-chip :color="getColor(item.EditionStatus)" @click="showCancelDialog(item)">
                {{ item.EditionStatus}}
              </v-chip>
      </template>
      <template v-slot:item.Status="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip v-if="item.Status.id == 1" color="blue" v-on="on">
                 HK
              </v-chip>
              <v-chip v-else color='green' v-on="on">
                 Pre Reserva
              </v-chip>
            </template>
            Ticket/No Ticket
          </v-tooltip>
      </template>
      <template v-slot:item.visa="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip v-if="item.visa" color="green" v-on="on">
                 Visado
              </v-chip>
              <v-chip v-else color='blue' v-on="on">
                 ---
              </v-chip>
            </template>
            Visar/Confirmar
          </v-tooltip>
      </template>
            <template v-slot:item.actions="{ item }">
                     <div>
                      <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="showDetails(item)"
                      >
                      mdi-details
                      </v-icon>
                      <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="showDuplicarDialog(item)"
                      >
                      mdi-content-duplicate
                      </v-icon>
                      <!--<v-icon v-if="item.editStatus != 'CANCELADO' && this.idaBookings[this.idaBookings.length -1].flight.destinyAirport.city == 'MANAGUA'"
                        medium
                        class="mr-2"
                        @click="showHostalBooking(item)"

                      mdi-home
                      </v-icon>
                      -->
                      <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>

   <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Eliminacion</v-card-title>
                  <v-card-text>Desea Eliminar el Itinerario?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
     <v-dialog v-model="dialogCancel" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Cancelacion</v-card-title>
                  <v-card-text>Desea Cancelar el Itinerario {{itemToCancel.code}}</v-card-text>
                    <label class="col-sm-6 control-label ">
                        Razon de Cancelacion
                    </label>
                    <v-textarea
                    solo
                    id="remarks"
                    v-model="remarks"
                    name="remarks">
                 </v-textarea>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogCancel = false">Abortar Operacion</v-btn>
                    <v-btn color="primary" text @click="cancelItem()">Cancelar Boleto</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { UPDATE_ITINERARY_MUTATION } from '../constants/graphql'
export default {
  name: 'ItineraryList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Itinerario', value: 'code' },
          {text:  'Pasajeros', value:'paxs'},
          { text: 'Primer vuelo', value: 'firstFlight' },
          { text: 'Fecha de salida', value: 'departureDate' },
          { text: 'Creado Por', value: 'username' },
          { text: 'Fecha de Creacion', value: 'createdAt' },
          { text: 'Editable', value: 'EditionStatus' },
          { text: 'Ticket', value: 'Status'},
          { text: 'Visa', value: 'visa' },
          { text: 'Action', value: 'actions'},
        ],
        dialogDelete: '',
        dialogDuplicar:'',
        itinerario: null,
        filterItins: [],
        filterBookings:[],
        itinerarios:[],
        tableKey:0,
        allItineraries:[],
        updated:0,
        totalItineraries:-1,
        searchIti:'',
        dialogCancel: false,
        remarks: "",
        itemToCancel:'',
     }
  },
  components: {

  },
  computed:{
    loading(){
        if(this.filterItins.length>0)
            return false
         else
             return true
    },
  },
  watch:{
    itineraries(n,o)
        {
            console.log(n.lenght+'>>'+o.lenght)
        }
  },
  async created(){
        //
      const filterItins = new Array()
      const Itineraries = await this.$apollo.query({
        query: gql`query {
          allItineraries {
          id
          code
  		  isDeleted
  		  dateCreated
  		  reubicado
  		  visa
  		  Status{
  		    id
  		    name
  		  }
  		  EditionStatus{
  		    id
  		    name
  		  }
  		  createdBy{
  		    id
            username
          }
          itinBookings{
            id
            pax{
              id
              firstName
              familyName
              civil{
              name
              id
              }
            }
            flight{
              id
              flightNumber
              airline{
                id
                iata
              }
              dateDeparture
              dateArrive
            }
            position
            ida
          }
      }
    }`,fetchPolicy: "network-only"
    })

    //console.log(pagesItineraries)
    //this.totalItineraries = pagesItineraries.data.itineraries.objSize
    //console.log(this.totalItineraries)
    this.allItineraries = Itineraries.data.allItineraries
    let filtrados = this.allItineraries.filter(itineraries =>itineraries.itinBookings.length > 0 && itineraries.isDeleted == 0)
        console.log("Este es la lista de itinerarios: "+this.allItineraries.length+">>"+filtrados.length)
        filtrados.forEach(itin => {
            const id = itin.id
            const code = itin.code
            var firstFlight = ''
            var departureDate = ''
            var paxs = new Array()
            const isDeleted = itin.isDeleted
            this.filterbokings = itin.itinBookings.filter(booking => booking.position == 0 && booking.ida == 1)

            if(this.filterbokings.length > 0){
                  this.filterbokings.forEach(booking => {
                        paxs.push(" "+booking.pax.civil.name+' '+booking.pax.firstName +" "+booking.pax.familyName)
                        var distinctPax = []
                        for (var i = 0; i < paxs.length; i ++)
                            if(!distinctPax.includes(paxs[i]))
                             distinctPax.push(paxs[i])
                        paxs = distinctPax
                  })
                  firstFlight = this.filterbokings[0].flight.airline.iata+this.filterbokings[0].flight.flightNumber
                  departureDate = this.filterbokings[0].flight.dateDeparture.substr(11,5) +' del '+this.filterbokings[0].flight.dateDeparture.substr(0,10)
                  //pax = paxs[0]//this.filterbokings[0].pax.firstName +" "+this.filterbokings[0].pax.familyName
           }
            const username = itin.createdBy.username
            const EditionStatus = itin.EditionStatus.name
            const dateCreated = itin.dateCreated
            const tmpitin ={
            id: id,
            createdAt:dateCreated,
            code:code,
            EditionStatus:EditionStatus,
            firstFlight:firstFlight,
            departureDate:departureDate,
            username:username,
            isDeleted:isDeleted,
            paxs:paxs,
            reubicado:itin.reubicado,
            visa:itin.visa,
            Status:itin.Status
        }
            filterItins.push(tmpitin)
        })
    console.log("Created")
    this.filterItins = filterItins
  },
  methods: {
    showDuplicarDialog(item){
     if(this.group == 1){
            this.itemToCopy = item
            this.dialogDuplicar = true
        }
      },
   doubleItin(){
       this.$router.push('/newItinerary/'+this.itemToCopy.id)
    },
    showCancelDialog(item){
     if(this.group == 1)
      if(item.editStatus != "CANCELADO"){
            this.itemToCancel = item
            this.dialogCancel = true
        }
      },

      cancelItem(){
         this.dialogCancel = false
         this.cancelItin(this.itemToCancel.id, this.remarks)
      },

      cancelItin(itinerary,remarks,EditionStatus = 3 ){
            this.itemToCancel.EditionStatus = "CANCELADO"
            this.$apollo.mutate({
            mutation: UPDATE_ITINERARY_MUTATION,
            variables: {
                itinerary,
                remarks,
                EditionStatus,
            }
            })
        },

      getReubColor (status) {
        if (status) return 'brown'
        else return 'white'
      },
      getColor (status) {
        if (status == "CANCELADO") return 'red'
        else if (status == "CONFIRMED") return 'blue'
        else return 'grey'
      },
        rowClasses: function (status) {
          switch (status){
            case "BORRADOR":
              return "row_lightGrey"
            case "CONFIRMED":
              return "row_lightBlue"
            case "CANCELADO":
              return "row_red"
            default:
              return "row_white"
            }
        },

    distinctItin (array) {
        var distinctItinId = []
        var distinctItin = []
        for (var i = 0; i < array.length; i ++)
            if(!distinctItinId.includes(array[i].id)){
             distinctItinId.push(array[i].id)
             distinctItin.push(array[i])
             }
        return distinctItin
    },
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full',timeStyle: 'long' }).format(new Date(date))
    },
    displayableTime (date) {
      return new Intl.DateTimeFormat('en-US', { timeStyle: 'long' }).format(new Date(date))
    },
   selectItem: function(item) {
      if (confirm('Seleccionar este pasajero? ' + item.firstName +" "+item.familyName)) {
            //this.$emit('paqueteselect', subscr.id)
            this.$router.push('/passenger/'+ item.passportNumber)
       }
    },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addItineario(){this.$router.push('fastItin')},

       showDetails: function(item){
            this.$router.push('/itinerary/'+ item.id)
          },

        updateItin(itinerary, isDeleted){
            this.$apollo.mutate({
            mutation: UPDATE_ITINERARY_MUTATION,
            variables: {
                itinerary,
                isDeleted,
            }
            })
        },
        updateBooking(booking, isDeleted){
            this.$apollo.mutate({
            mutation: UPDATE_ITINERARY_MUTATION,
            variables: {
                booking,
                isDeleted,
            }
            })
        },
        deleteItem() {
                const index = this.filterItins.findIndex(itins => itins.id ==this.itinerario.id)
                console.log("updated_created>>"+index)
                if (index >= 0) this.filterItins.splice(index, 1)
            this.updated= this.updated+1
           // this.filterItins.length = 0
            this.updateItin(this.itinerario.id,1)
            this.dialogDelete = false
        },
        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.row_lightGrey {
  background-color: rgb(215,215,44)
}
.row_lightBlue {
  background-color: rgb(114,114,67)
}
.row_red {
  background-color: rgb(215,215,44)
}
.row_white {
  background-color: rgb(114,114,67)
}

.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
