<template >
    <div id="wrapper">
 <!--       <printPage v-if="confirmed" ref="frame" />-->
        <div id="toExport" ref="toPrint" class="col-sm-12" v-if="itinerary">
        <div align="center" pad="10">
              <v-img
                  max-height="220"
                  max-width="600"

                  :src="require('../assets/logoblanco.png')">
                  </v-img>
              </div>
       <v-spacer></v-spacer>
    <!--<v-app-bar
      color="#ed4c05"
      dark
      rounded
    >

        <v-spacer></v-spacer>
        <v-divider
             vertical
        ></v-divider>
        <v-spacer></v-spacer>
      <v-toolbar-title >
          <span class="subheading" align="center">ITINERARIO DE VIAJE # {{itinerary.code}}.  {{idaBookings[0].flight.sourceAirport.city}}, {{idaBookings[0].flight.sourceAirport.country}} A</span>
          <v-divider
              class="mx-4"
              vertical
            >
          </v-divider>
          <span class="subheading">{{idaBookings[idaBookings.length -1].flight.destinyAirport.city}}, {{idaBookings[idaBookings.length -1].flight.destinyAirport.country}}</span>
      </v-toolbar-title>
     <v-spacer></v-spacer>
        <v-divider color="white"
             vertical
        ></v-divider>
        <v-spacer></v-spacer>
      <v-btn color="primary" ><v-icon dark right>mdi-account-convert</v-icon></v-btn>

     </v-app-bar>-->
    <v-container>
        <v-card
            elevation = 7
            >
        <v-card-title class="blue darken-4 center" >
            <span class="text-h7 white--text" style="align:center;">{{title}}</span>
        </v-card-title>
        <v-data-table
            :headers="passengers"
            :items="passengerSet"
            :items-per-page="9"
            class="elevation-1"
            :key="paxtablekey"
            :sort-by.sync="sortPaxBy"
            :sort-desc.sync="sortDesc"
            hide-default-footer
        >
            <template v-slot:item.pnr="{ item }">
                <td>{{ item.pnr}} </td>
            </template>


            <template    v-slot:item.actions="{ item }">
                     <div  class="text-truncate">
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showPaxDetails(item)"
                      >
                      mdi-details
                      </v-icon>
                      <v-icon v-if="isAdmin"
                        medium
                        color ="red"
                        @click="showTiketDialog(item)"
                      >
                      mdi-pencil
                      </v-icon>

                  </div>
          </template>
        </v-data-table>
        </v-card>
         <template>
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-dialog
                transition="dialog-top-transition"
                max-width="600"
                v-model="tiketDialog"
              >
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >Tickets para: {{itemToEdit.pax.familyName}} {{itemToEdit.pax.firstName}}</v-toolbar>
                      <v-card-title v-if="showAllFlights">Vuelos de Ida</v-card-title>
                     <v-card-text v-if="!showAllFlights">
                      <div class="text-h5 pa-4" >
                          Vuelo # {{idaPaxBookings[0].flight.airline.iata}}{{idaPaxBookings[0].flight.flightNumber}}
                          <v-text-field v-model="idaPaxBookings[0].ticket" @input="idaPaxBookings[0].ticket = idaPaxBookings[0].ticket.toUpperCase()" label = "TICKET"></v-text-field><v-text-field v-model="idaPaxBookings[0].pnr" @input="idaPaxBookings[0].pnr = idaPaxBookings[0].pnr.toUpperCase()" label="PNR"></v-text-field>
                      </div>
                    </v-card-text>
                    <v-card-text v-else>
                      <div class="text-h5 pa-4" v-for="item in idaPaxBookings" :key ="item.position">
                          Vuelo # {{item.flight.airline.iata}}{{item.flight.flightNumber}}
                          <v-text-field v-model="item.ticket" @input="item.ticket = item.ticket.toUpperCase()" label = "TICKET"></v-text-field><v-text-field v-model="item.pnr" @input="item.pnr = item.pnr.toUpperCase()" label="PNR"></v-text-field>
                      </div>
                    </v-card-text>
                 <v-spacer> <v-divider inset></v-divider> </v-spacer>
                 <v-card-title v-if="showAllFlights">Vuelos de Regreso</v-card-title>
                    <v-card-text v-if="showAllFlights">
                      <div class="text-h5 pa-4" v-for="item in returnPaxBookings" :key ="item.position">
                          Vuelo # {{item.flight.airline.iata}}{{item.flight.flightNumber}}
                          <v-text-field v-model="item.ticket" @input="item.ticket = item.ticket.toUpperCase()" label = "TICKET"></v-text-field><v-text-field v-model="item.pnr" @input="item.pnr = item.pnr.toUpperCase()" label="PNR"></v-text-field>
                      </div>
                    </v-card-text>
                      <v-checkbox
                          v-model="showAllFlights"
                          label="Mostrar todos los vuelos"
                        ></v-checkbox>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="updatePax"
                      >Salvar</v-btn>
                      <v-btn
                        text
                        @click.close="dialog.value = false"
                      >Salir</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
        </template>
        <v-spacer> <v-divider inset></v-divider> </v-spacer>
        <v-card
            elevation = 7
            >
        <v-card-title class="blue darken-4">
            <v-icon medium color ="white" >mdi-flight-takeoff</v-icon>
            <span class="text-h5 white--text">Vuelos de Ida</span>
        </v-card-title>
        <v-data-table
            :headers="idaflightHeaders"
            :items="idaBookings"
            :items-per-page="5"
            class="elevation-1"
            hide-default-footer
            :sort-by.sync="sortflightBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:item.iataCode="{ item }">
                <img class="product_image" :src="getUrl(item.flight.airline.iata)" width=" 80px">
            </template>
            <template v-slot:header.dateDeparture="{ header }">
              <v-icon mediun color="blue darken-2"> mdi-airplane-takeoff </v-icon>{{ header.text }}
            </template>
            <template v-slot:header.dateArrive="{ header }">
                <v-icon mediun color="blue darken-2"> mdi-airplane-landing </v-icon>{{ header.text }}
            </template>

            <template v-slot:item.flightNumber="{ item }">
                <td>{{ item.flight.airline.iata}}-{{ item.flight.flightNumber}} </td>
            </template>
            <template v-slot:item.airportDeparture="{ item }" >
                <td class="text-h7">{{ item.flight.sourceAirport.city}},({{ item.flight.sourceAirport.country}}), {{ item.flight.sourceAirport.icaoCode}} </td>
            </template>
            <template v-slot:item.dateDeparture="{ item }">
                <td>{{ item.flight.dateDeparture.substr(0,10)}}, {{ item.flight.dateDeparture.substr(11,5)}}hrs</td>
            </template>
            <template v-slot:item.airportArrive="{ item }">
                <td class="text-h7">{{ item.flight.destinyAirport.city}},({{ item.flight.destinyAirport.country}}), {{ item.flight.destinyAirport.icaoCode}}  </td>
            </template>
            <template v-slot:item.dateArrive="{ item }">
                <td>{{ item.flight.dateArrive.substr(0,10)}}, {{ item.flight.dateArrive.substr(11,5)}}hrs</td>
            </template>
            <template v-slot:item.Equipaje="{ item }">
                <td>{{ item.bag.quantity}} BULTO {{ item.bag.weight}}{{ item.bag.unit.name}} / </td>
            </template>
        </v-data-table>
        </v-card>

         <v-spacer><v-divider inset></v-divider> </v-spacer>
        <v-card v-if="itinerary.roundtrip"
            elevation = 7
            >
        <v-card-title class="blue darken-4">
            <v-icon medium color ="primary" >mdi-Airplane</v-icon>
            <span class="text-h5 white--text">Vuelos de Regreso </span>
        </v-card-title>
        <v-data-table v-if ="returnBookings"
            :headers="idaflightHeaders"
            :items="returnBookings"
            :items-per-page="5"
            class="elevation-1"
            hide-default-footer
            :sort-by.sync="sortflightBy"
            :sort-desc.sync="sortDesc"

        >
            <template v-slot:header.dateDeparture="{ header }">
              <v-icon mediun color="blue darken-2"> mdi-airplane-takeoff </v-icon>{{ header.text }}
            </template>
	<template v-slot:header.dateArrive="{ header }">
		<v-icon mediun color="blue darken-2"> mdi-airplane-landing </v-icon>{{ header.text }}
	</template>
             <template v-slot:item.iataCode="{ item }">
                <img class="product_image" :src="getUrl(item.flight.airline.iata)" width=" 80px">
            </template>
            <template v-slot:item.flightNumber="{ item }">
                <td>{{ item.flight.airline.iata}}-{{ item.flight.flightNumber}} </td>
            </template>
            <template v-slot:item.airportDeparture="{ item }">
                <td>{{ item.flight.sourceAirport.city}},({{ item.flight.sourceAirport.country}}),  {{ item.flight.sourceAirport.icaoCode}} </td>
            </template>
            <template v-slot:item.dateDeparture="{ item }">
                <td>{{ item.flight.dateDeparture.substr(0,10)}}, {{ item.flight.dateDeparture.substr(11,5)}}hrs</td>
            </template>
            <template v-slot:item.airportArrive="{ item }">
                <td>{{ item.flight.destinyAirport.city}},({{item.flight.destinyAirport.country}}), {{ item.flight.destinyAirport.icaoCode}}  </td>
            </template>
            <template v-slot:item.dateArrive="{ item }">
                <td>{{ item.flight.dateArrive.substr(0,10)}}, {{ item.flight.dateArrive.substr(11,5)}}hrs</td>
            </template>
            <template v-slot:item.equipaje="{}">
                <td>{{itinerary.equipaje.name}}</td>
            </template>
        </v-data-table>
        </v-card>

    </v-container>
  <v-dialog v-model="dialogDetails" max-width="700px" max-height="700px">
                 <v-card>
                  <v-card-title>Detalles del Pasajero {{ paxDetails.firstName }} {{ paxDetails.familyName }}</v-card-title>
                    <v-card-text>Pasaporte: {{ paxDetails.passportNumber }}</v-card-text>
                    <v-card-text>email: {{ paxDetails.email }}</v-card-text>
                     <v-card-text>Telefono: {{ paxDetails.phoneNumber }}</v-card-text>
                    <v-card-text>Reservas:</v-card-text>
                    <v-card v-for="reserva in paxBookings" v-bind:key="reserva.id" >
                        <v-card-text >Itinerario: {{ reserva.itinerary.code }}, Origen: {{ reserva.flight.sourceAirport.city }}({{ reserva.flight.sourceAirport.icaoCode }}), Destino: {{ reserva.flight.destinyAirport.city }}({{ reserva.flight.destinyAirport.icaoCode }})</v-card-text>
                        <!--<v-card-text>Creado Por: {{ pax.createdBy.username }}</v-card-text>
                        <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                        <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                        <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                   </v-card>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
<v-spacer><v-divider inset></v-divider> </v-spacer>
       <!-- <div style="break-after:page"></div>-->
       <!-- <v-layout row>
            <v-card>
                    <v-card-title
                    >INFORMACION A VIAJEROS</v-card-title>
                    <v-card-text>
                      <div class="text-h7 pa-4" >
                          Para ingresar en su territorio, las autoridades nicaragüenses solicitan a los viajeros que entren a Nicaragua por vía aérea, marítima o terrestre certificado de vacunación covid-19, ya sea digital o impreso, que refleje pauta de vacunación completa. En el caso de Sputnik Light y Janssen, es suficiente con una dosis.  De tener la pauta de vacunación basada en Soberana o no tenerla el viajero debe presentar PCR Negativo. D'VIAJEROS (https://dviajeros.mitrans.gob.cu/) es un proceso que facilita y mejora la experiencia de los viajeros en su tránsito por la República de Cuba.
                      </div>
                    </v-card-text>
                    </v-card>
            </v-layout>
        <v-row>
                <v-col >
                  <v-list dense>
                    <v-list-item
                    >
                     <v-list-item-avatar>
                        <v-img :src="require('../assets/MAPS.png')"></v-img>
                     </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Direccion</v-list-item-title>
                          <v-list-item-title>{{allAgencies[0].address}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </v-list>
              </v-col>
                            <v-col >
                  <v-list dense>
                    <v-list-item
                    >
                     <v-list-item-avatar>
                        <v-img :src="require('../assets/INSTA.png')"></v-img>
                     </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Instagram</v-list-item-title>
                          <v-list-item-title>{{allAgencies[0].instagram}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </v-list>
              </v-col>
              <v-col >
                <v-list dense>
                  <v-list-item
                    >
                     <v-list-item-avatar>
                        <v-img :src="require('../assets/GMAIL.png')"></v-img>
                     </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>email</v-list-item-title>
                          <v-list-item-title>{{allAgencies[0].email}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                   </v-list>
                 </v-col>
                 <v-col >
                  <v-list>
                    <v-list-item
                    >
                     <v-list-item-avatar>
                        <v-img :src="require('../assets/WHATASP.png')"></v-img>
                     </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title>Telefonos</v-list-item-title>
                          <v-list-item-title>{{allAgencies[0].tlf1}}, {{allAgencies[0].tlf2}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                   </v-list>
                  </v-col>
        </v-row>
        -->
            <span>Reubicado: {{itinerary.reubicado}}</span><br>
            <span>Para visa: {{itinerary.visa}}</span><br>
            <span>OBSERVACIONES DE LA RESERVA:</span><br>
            <span>Llegada al aeropuerto 4 horas antes de la salida del 1er vuelo. Debe portar consigo toda la documentacion impresa</span><br>
            <span>Cancelaciones o cambios de fecha no son permitidos. El no presentarse en la fecha y horarios indicados en este documento implicará la perdida total de la reserva:</span><br>
            <span>Equipaje Contratado: {{itinerary.equipaje.name}}(BODEGA)/{{itinerary.hequipaje.name}}(CABINA)</span>
<v-spacer><v-divider inset></v-divider> </v-spacer>
  </div>
        <v-layout row>
          <v-btn class="ma-2" @click="exportar" primary v-if="itneraryEditionStatus==2">Exportar</v-btn>
          <v-btn class="ma-2" @click="exportarHostal" primary v-if="this.itneraryEditionStatus == 2 && this.idaBookings[this.idaBookings.length -1].flight.destinyAirport.city == 'MANAGUA'">Reserva de Hostal</v-btn>

          <v-btn class="ma-2" @click="confirmar" primary v-if="allowConfirm">Confirmar</v-btn>
          <v-spacer></v-spacer>
           <v-btn class="ma-2" color="red" @click= "gohome" >
               <v-icon medium class="mr-2">
                mdi-close
               </v-icon>
               {{$t('lang.itinerary.sclose')}}
           </v-btn>
        </v-layout>
  </div>

</template>

<script>
import gql from 'graphql-tag'
import { useUserStore } from "@/stores/user";
import {computed} from "vue";
//import jsPDF from 'jspdf'
//import html2canvas from "html2canvas"
import { mdiAirplaneTakeoff } from '@mdi/js'
import { UPDATE_BOOKING_MUTATION } from '../constants/graphql'
import { UPDATE_ITINERARY_MUTATION } from '../constants/graphql'
//import printPage from '@/components/printPAge.vue'
export default {
  name: 'ItineraryDetails',
  components: {
       //printPage
  },
  setup() {
        const userStore = useUserStore();
        const userInfo  = computed(() => userStore.getUser);
        return { userStore, userInfo};
      },
  apollo: {
    allAgencies: gql`query {
       allAgencies
        {
          id
          name
          address
          email
          tlf1
          tlf2
          instagram
          facebook
          longitud
          latitud
        }
    }`
  },
  data () {
    return {
      confirmed: false,
      sortflightBy: 'position',
      sortPaxBy: 'pax.familyName',
      sortDesc: false,
      value: '',
      size: 500,
      exportTo : false,
      planeTakeOff: mdiAirplaneTakeoff,
      itinerary: null,
      idaBookings: [],
      returnBookings: [],
      passengerSet: [],
      uniquePassengerSet: [],
      showAllFlights:false,
      idaflightHeaders: [
          {
            text: 'Airline',
            align: 'start',
            value: 'iataCode', sortable: false
          },
          { text: 'Vuelo', value: 'flightNumber',sortable: false },
          { text: 'Salida', value: 'dateDeparture',sortable: false},
          { text: '', value: 'airportDeparture',sortable: false },
          { text: 'Llegada', value: 'dateArrive' ,sortable: false},
          { text: '', value: 'airportArrive',sortable: false },
          {text:  'Position', value: "position",sortable: false, align: ' d-none'},
        ],
        passengers : [
          { text: 'ID', value: 'pax.passportNumber' ,sortable: false},
          { text: 'Apellido', value: 'pax.familyName',sortable: false },
          { text: 'Nombre', value: 'pax.firstName',sortable: false },
          { text: 'PNR', value: 'pnr' ,sortable: false},
          { text: 'ETICKET', value: 'ticket' ,sortable: false},
          { text: 'ESTADO', value: 'status.name',sortable: false },
          { text: 'Acciones', value: 'actions',sortable: false },
        ],
        tiketDialog: 0,
        itemToEdit: '',
        idaPaxBookings:[],
        returnPaxBookings:[],
        pnr:'',
        ticket:'',
        booking:'',
        status: 'Preserva',
        paxtablekey:'',
        itneraryEditionStatus:'',
        roles: JSON.parse(this.userInfo).groups,
        isAdmin:false,
        allowConfirm:false,
        isTicket: 2,
        paxToShow:'',
        dialogDetails:false,
        paxBookings:[],
        paxDetails:'',
        title:"",
    }
  },
  computed:{

  }
  ,
  async mounted () {
      if(this.roles.filter(group => group.id == 1).length > 0)
      {
        this.isAdmin = true
        this.allowConfirm = true
      }
      else{
        this.isAdmin = false
        this.allowConfirm = false
      }
      console.log("isAdmin: "+this.isAdmin)
    },
  async created () {
        this.roles.forEach(group => {
        console.log(group.name)
        if(group.name == "adminstrador"){
          this.isAdmin = true
        }
      })
  /*  const passengers = await this.$apollo.query({
        query: gql`query ($it: Int!) {
            bookingsByItinerary(itinerary:$it)
              {
                id
                flight{
                    id
                    flightNumber
                    airline{iata}
                }
                pax
                {
                  id
                  firstName
                  familyName
                  passportNumber
                  title
                }
                pnr
                ticket
              }
            }`,
        variables: {
          it: this.$route.params.id,
        },
    })
*/

    //alert(this.distinctPax(this.passengerSet))
    const itinerary = await this.$apollo.query({
        query: gql`query ($id: ID!) {
            itineraryById(id:$id) {
               id
               code
  			   remarks
  			   roundtrip
  			   reubicado
  			   visa
  			   Status{
  			    id
  			    name
  			   }
  			   EditionStatus{
                id
                name
               }
  			    createdBy{
  			    id
                username
                lastName
              }
              equipaje
  			   {
                   id
                   name
  			   }
  			   hequipaje
  			   {
                   id
                   name
  			   }
          itinBookings{
            id
            status{
               id
               name
            }
            pax{
              id
              firstName
              familyName
              passportNumber
              title
            }
            flight{
              id
              flightNumber
              airline{
                id
                name
                iata
              }
              sourceAirport{
                id
                name
        		icaoCode
        		city
        		country
              }
              dateDeparture
              destinyAirport{
                id
                name
                city
                country
                icaoCode
              }
              dateArrive
            }
            bag{
                id
                name
                weight
                quantity
                unit{
                    id
                    name
                }
              }
            pnr
            ticket
            position
            ida
            status{
                id
            }
          }
         }
        }`,
        variables: {
          id: this.$route.params.id,
        },fetchPolicy: "network-only"
    })

    this.passengerSet = itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida > 0 && booking.position == 0)
        //this.passengerSet = this.passengerSet.pax

    this.itinerary = itinerary.data.itineraryById
    this.itneraryEditionStatus = this.itinerary.EditionStatus.id
    this.isTicked = this.itinerary.Status.id
    console.log(this.itinerary.EditionStatus.name)
    if(this.itinerary.EditionStatus.name == "CANCELADO")
       {
         this.allowConfirm = false
       }
    if(this.itinerary.Status.id == 1)
           this.title = "RESERVA CONFIRMADA"
        else
           this.title = "PRESERVA"
    console.log(this.itinerary.Status.id)
    //hide actions buttons if confirmed
      if(this.itneraryEditionStatus == 2)
      {
        //this.passengers.splice(this.passengers.length-1, 1)
        this.allowConfirm = false
      }
    this.value = this.itinerary.code
    this.idaBookings = this.distinctFlight(itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida > 0)).sort((a,b) => a.position - b.position)
    if(this.idaBookings[0].status.id == 1){
           this.title = "RESERVA CONFIRMADA"
           this.isTicket = 1
          }
        else
           this.title = "PRESERVA"
    this.returnBookings = this.distinctFlight(itinerary.data.itineraryById.itinBookings.filter(booking => booking.ida == 0)).sort((a,b) => a.position - b.position)
  },
  methods: {
        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
    canExportHostal(){
        console.log(this.idaBookings[this.idaBookings.length -1].flight.destinyAirport.city)
        if(this.itneraryEditionStatus == 2 && this.idaBookings[this.idaBookings.length -1].flight.destinyAirport.city == "MANAGUA")
            return true
        else
            return false
    },
    showPaxDetails(item){
       this.paxToShow = item
       this.dialogDetails = true
       this.loadPaxDetails(item.pax.passportNumber)
     },

async loadPaxDetails(paxId){
        await this.$apollo.query({
          query: gql`query ($pp: String!) {
            paxByPp(pp:$pp) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateValid
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }
              paxBookings{
                      id
                      itinerary{
                        id
                        code
                        isDeleted
                      }
                      flight{
                        id
                        flightNumber
                        sourceAirport{
                           id
                           city
                           icaoCode
                        }
                        destinyAirport{
                           id
                           city
                           icaoCode
                        }

                      }
                      position
                      ida
                    }

            }
            }`,fetchPolicy: "network-only",
            variables: {
              pp: paxId,
            }
        }).then((data)=>{
              this.paxDetails = data.data.paxByPp
              this.paxBookings = this.paxDetails.paxBookings.filter(booking => booking.position == 0 && booking.itinerary.isDeleted == 0)
              this.dialogDetails = true
              this.update++
        })

      },
    getUrl(code){
       if(code == "T9")
         return "https://turpialairlines.com/img/logo-turpial.png"
       if(code=="S6")
         return "https://airhex.com/images/airline-logos/sunrise-airways.png"
      if(code=="Y2")
         return "https://aircentury.com/wp-content/uploads/2023/09/logotipo-air-century-1.svg"
      if(code=="8W")
         return "https://flyallways.com/wp-content/uploads/2022/09/FAW-logo-1024x359-1.png"
      if(code == "DO")
         return "https://skyhighdominicana.com/wp-content/uploads/2023/04/SKYhigh-Logo-SKYhigh.svg"
      if(code == "WN")
         return "https://logos-world.net/wp-content/uploads/2020/10/Southwest-Airlines-Logo-120x67.png"
      if(code=="VB")
        return "https://media.staticontent.com/media/pictures/b7ee1c9a-49b9-4724-8c0e-d4ae07e938e7"
      else
        return "https://s1.apideeplink.com/images/airlines/" + code + ".png"
    },
    //metodo para actualizar tikect y PNR
    showTiketDialog(item){
      if(this.itinerary.EditionStatus.name != "CANCELADO"){
        console.log(item)
        this.itemToEdit = item
        this.idaBookingsByPax()
        this.tiketDialog = 1
        }
      else
        alert("Este Boleto no se puede editar")
     },
     idaBookingsByPax(){
        this.idaPaxBookings = this.itinerary.itinBookings.filter(booking => booking.pax.id == this.itemToEdit.pax.id && booking.ida == 1)
        this.idaPaxBookings = this.customSort(this.idaPaxBookings, "position", 1)
        this.returnPaxBookings = this.itinerary.itinBookings.filter(booking => booking.pax.id == this.itemToEdit.pax.id && booking.ida == 0)
        this.returnPaxBookings = this.customSort(this.returnPaxBookings, "position",1)
        //console.log("Esto>>"+this.paxBookings)
     },
     callUpdateBooking(pnr,ticket,id){
        this.pnr = pnr
        this.ticket = ticket
        this.booking = id
        this.status = 1
        this.updateBooking()
        console.log(this.pnr+'::'+this.ticket+'::'+this.booking)
      },
    updatePax(){
        const estado ={
            id:1,
            name:"CONFIRMADO"
        }
        this.idaPaxBookings.forEach(pBooking => {
            this.callUpdateBooking(pBooking.pnr,pBooking.ticket, pBooking.id)
            pBooking.status = estado
            })
        this.returnPaxBookings.forEach(pBooking => {
            this.callUpdateBooking(pBooking.pnr,pBooking.ticket, pBooking.id)
            pBooking.status = estado
            })
        this.tiketDialog = 0
        this.updateItinStatus(this.$route.params.id, 1)
        //this.$router.go()
     },
    updateItinStatus(itinerary, Status){
            this.$apollo.mutate({
            mutation: UPDATE_ITINERARY_MUTATION,
            variables: {
                itinerary,
                Status,

            }
            })
            .then(() =>{
               this.isTicket = Status
               this.title = "RESERVA CONFIRMADA"
               this.status = "Reservado"
               console.log("Reservado")
            })
        },
    updateItinEditStatus(itinerary, EditionStatus){
            this.$apollo.mutate({
            mutation: UPDATE_ITINERARY_MUTATION,
            variables: {
                itinerary,
                EditionStatus,

            }
            })
            .then(() =>{
               this.itneraryEditionStatus=2
               this.allowConfirm = false
               this.
               console.log("confirmado")
            })
        },
    async updateBooking(){
        const {
        booking,
        pnr,
        ticket,
        status,
        } = this.$data
        this.$apollo.mutate({
        mutation: UPDATE_BOOKING_MUTATION,
        variables: {
            booking,
            pnr,
            ticket,
            status,
        }
        }).then(() =>{
        this.$apollo.query({
        query: gql`query ($id: ID!) {
            itineraryById(id:$id) {
               id
               code
  			   remarks
  			   roundtrip
  			   EditionStatus{
                id
                name
               }
  			    createdBy{
  			    id
                username
                lastName
              }
          itinBookings{
            id
            pax{
              id
              firstName
              familyName
              passportNumber
              title
            }
            flight{
              id
              flightNumber
              airline{
                id
                name
                iata
              }
              sourceAirport{
                id
                name
        		iataCode
        		city
        		country
              }
              dateDeparture
              destinyAirport{
                id
                name
                city
                country
                iataCode
              }
              dateArrive
            }
            pnr
            ticket
            position
            status{
                id
                name
            }
            ida
          }
         }
        }`,
        variables: {
          id: this.$route.params.id,
        },
    })
        })
    },
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
    displayableTime (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
    distinctPax (array) {
        var distinct = []
        var distinctBooking = []
        console.log(array.length)
        for (var i = 0; i < array.length; i ++)
            if(!distinct.includes(array[i].pax)){
             distinct.push(array[i].pax)
             distinctBooking.push(array[i])
             }
        return distinctBooking
    },
    distinctFlight (array) {
        var distinctFlight = []
        var distinctBooking = []
        for (var i = 0; i < array.length; i ++)
            if(!distinctFlight.includes(array[i].flight.id)){
             distinctFlight.push(array[i].flight.id)
             distinctBooking.push(array[i])
             }
        console.log("Bookings>>" +distinctBooking.length)
        return distinctBooking
    },
    compare(a,b){
        if(a > b)
            return 1;
        else
            return -1;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === "position") {
          if (isDesc) {
            return this.compare(a.position, b.position);
          } else {
            return this.compare(b.position, a.position);
          }
        }
      });
      return items;
    },
  /*  sendConfirmPaymentEmail() {
        Email.send({
            Host : "smtp-adriitours.alwaysdata.net",
            Username : "adriitours",
            Password : "AdriiAdmin",
            To : 'vladimir.roldan@gmail.com',
            From : "suport@kingsjourney.online",
            Subject : "Confirmation email",
            Body : "this is a payment confirmation email"
        }).then(
          message => alert(message)
        )
    },*/
    async confirmar() {
        if(confirm("Desea confirmar esta reserva?"))
        {
        console.log(window.location.hostname)
        //this.passengers.splice(this.passengers.length-1, 1);
        this.updateItinEditStatus(this.$route.params.id, 2)
        this.paxtablekey++
        this.sendConfirmPaymentEmail()
        }
       },
/*    imprimir()
    {
      this.$refs.frame.print(this.$refs.toPrint);
    },*/
    exportar() {
      if(this.itinerary.Status.id == 1 || this.isTicket == 1){
       console.log(window.location.hostname)
       //window.open("https://window.location.hostname/api/download_reserva/"+this.$route.params.i, '_blank')
       window.location.href="https://traveladmin.dcano14k.com/download_reservak/"+this.$route.params.id
       //this.sendConfirmPaymentEmail()
       //window.location.href="https://localhost:8000/download_reserva/"+this.$route.params.id
       // this.downloadComponentInPDF(document.getElementById("toExport"))
        //this.saveAsPDF(document.getElementById("toExport"))
       }
       else
        window.location.href="https://traveladmin.dcano14k.com/download_prereservak/"+this.$route.params.id

     },
     exportarHostal() {
        // window.open("https://crystalapi.sgbcaribe.com/download_hostal/"+this.$route.params.i, '_blank')
         window.location.href="https://traveladmin.dcano14k.com/download_hostal/"+this.$route.params.id
        //this.downloadComponentInPDF(document.getElementById("toExport"))
        //this.saveAsPDF(document.getElementById("toExport"))
     },
 /*   async downloadComponentInPDF(Component) {
      var orien = 'l'
      await html2canvas(Component).then((canvas) => {
        const componentWidth = Component.offsetWidth
        const componentHeight = Component.offsetHeight

        orien = componentWidth >= componentHeight ? 'l' : 'p'

        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm'
      })
    const ratio = 216/280
    pdf.internal.pageSize.width = 216
    pdf.internal.pageSize.height = 280
    if(orien == 'l'){
        var heigh = 216*ratio
        pdf.addImage(imgData, 'PNG', 0, 0, 216,heigh)
    }
    else{
        pdf.addImage(imgData, 'PNG', 0, 0, 216, 280)
    }
    pdf.save("Reserva "+this.passengerSet[0].pax.passportNumber+'_'+this.passengerSet[0].pax.familyName+'.pdf')
    //pdf.save(this.itinerary.code+'.pdf')
  })
},
saveAsPDF(Component) {
        html2canvas(Component, {
            onrendered: function (canvas) {
                var wid;
                var hgt;
                var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas
                .height); //image data of canvas
                var hratio = hgt / wid;
                var doc = new jsPDF({
                    orientation: "landscape"
                });
                var width = doc.internal.pageSize.width;
                //var height = doc.internal.pageSize.height;
                var height = width * hratio;
                doc.addImage(img, 'JPEG', width*.025, 20, width*.95, height*.95);
                doc.save('AssetComparison.pdf')
            }
        });
    }*/
},
}
</script>

<style scoped>

</style>
