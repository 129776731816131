<template>
  <div>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="sellerList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :key = "tableKey"
        >
            <template v-slot:item.groups="{ item }">
                <td v-for="group in item.groups" v-bind:key="group.id">{{group.name}} </td>
            </template>
            <template v-slot:item.actions="{ item }">
                     <div >
                      <v-icon v-if="item.isActive"
                        medium
                        class="mr-2"
                        color="green"
                        @click="activate(item)"
                      >
                      mdi-check-circle
                      </v-icon>
                      <v-icon v-else
                        medium
                        class="mr-2"
                        color="red"
                        @click="activate(item)"
                      >
                      mdi-cancel
                      </v-icon>
                      <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="showDialogDetails(item)"
                      >
                      mdi-details
                      </v-icon>

                  </div>
          </template>
   </v-data-table>
         </v-card>

     <v-dialog v-model="dialogDetails" max-width="800px" max-height="800px" v-if="itemTo">
                <v-card>
                  <v-card-title>Detalles del Vendedor {{ itemTo.username }} </v-card-title>
                    <v-card-text>Nombre Completo: {{ itemTo.firstName }} {{ itemTo.lastName }}</v-card-text>
                    <v-card-text>Telefono: {{ itemTo.profile.movil }}</v-card-text>
                    <v-card-text>email: {{ itemTo.email }}</v-card-text>
                   <!-- <v-card-text>Creado Por: {{ itemTo.createdBy.username }}</v-card-text>
                     <v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>

                    <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                    <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                    <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SELLER_LIST } from '../constants/graphql'
//import { CREATE_SELLER_MUTATION } from '../constants/graphql'
import { UPDATE_SELLER_MUTATION } from '../constants/graphql'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'SellerList',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
  data() {
      return {
            search: '',
            headers: [
          {text: 'usuario', value: 'username'},
          {text: 'Nombre', value: 'firstName'},
          {text: 'Apellidos', value: 'lastName'},
          { text: 'Telefono', value: 'profile.movil' },
          { text: 'Grupos', value: 'groups' },
          { text: 'Email', value: 'email' },
          { text: 'Actions', value: 'actions' },
        ],
        allSellerList:[],
        dialogDelete: false,
        dialogDetails: false,
        dialogBook: false,
        itemTo:'',
        tableKey:0,
        username: (JSON.parse(this.userStore.getUser)).username,
        loading: false,

     }
  },
  components: {
  },
  props: {
    reservas: {
      type: Array,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    sellerList () {
      //return this.reservas

      return this.allSellerList
    },

  },

async mounted () {
     this.loadSellerList()
    },
  methods: {
  activate: function(item) {
      if (confirm('Se cambiara el estado del Vendedor: ' + item.username)) {
          this.changeItem(item.id,!item.isActive)
          item.isActive=!item.isActive
       }
    },

    changeItem(seller,isValid){
    console.log(isValid)
        this.$apollo.mutate({
        mutation: UPDATE_SELLER_MUTATION,
        variables: {
            seller,
            isValid,
        }
        })
},
   showDeleteDialog(item){
            this.itemTo = item
            this.dialogDelete = true

      },
      showDialogDetails(item){
            this.itemTo = item
            this.dialogDetails = true

      },
   deleteItem(){
        const index = this.sellerList.findIndex(itins => itins.id ==this.itemTo.id)
        console.log("updated_created>>"+index)
        if (index >= 0) this.sellerList.splice(index, 1)
        this.cancelarReserva(this.itemTo.id,true)
   },
   async cancelarReserva(id,cancel){
        this.$apollo.mutate({
        mutation: UPDATE_SELLER_MUTATION,
        variables: {
            id,
            cancel,
        }
        }).then(()=>{
           this.dialogDelete = false
           this.tableKey = this.tableKey+1
           //this.loadAllReservas()
        }
        )
   },

   async loadSellerList(){
     this.loading = true
     //var user = this.username
     await this.$apollo.query({
            query: SELLER_LIST,
            fetchPolicy: "network-only",
            }).then((data)=>{
            console.log(data.data.allUsers)
            this.allSellerList = data.data.allUsers
            this.loading = false
            })
   },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
