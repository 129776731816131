<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title>Lista de Rutas</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addRuta"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           Agregar Ruta
       </v-btn>
   </v-app-bar>
         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
             :headers="headers"
            :items="registeredRutas"
            :items-per-page="10"
            :search="search"
            :loading="loading"
            class="elevation-1"


firstFlightNumber
        >
            <template v-slot:item.flightNumber="{ item }">
                 <td>{{ firstFlightNumber(item.flights)}}</td>
            </template>
            <template v-slot:item.dateDeparture="{ item }">
                 <td>{{ item.dateDeparture.substr(0,10)}}, {{ item.dateDeparture.substr(11,5)}}</td>
            </template>
            <template v-slot:item.createdBy.username="{ item }">
                <td>{{ item.createdBy.username }}</td>
            </template>

            <template v-slot:item.actions="{ item }">
                     <div class="text-truncate">
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showDetailDialog(item)"
                      >
                      mdi-details
                      </v-icon>
                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-pencil
                      </v-icon>
                      <v-icon
                        medium
                        @click="showSerializeDialog(item)"
                      >
                      mdi-content-duplicate
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
   <v-dialog v-model="dialogSerialize" max-width="500px" max-height="700px" v-if="ruta">
                <v-card>
                  <v-card-title>Alerta de Edicion</v-card-title>
                  <v-card-text>Desea Modificar la Ruta?</v-card-text>

                      <v-layout row>

                          <v-flex xs12 ma-2 pa-2>
                           <label class="col-sm-10 control-label ">
                              Frecuencia
                           </label>
                           <v-text-field
                            solo
                            type = "number"
                            autocomplete="off"
                            id="frec"
                            v-model="ruta.frecuencia"
                            :rules="[rules.required]"
                            hint = "Este Campo es Obligatorio"
                            required
                            name="frec"
                            placeholder="Frecuencia en Dias">
                           </v-text-field>
                          <label class="col-sm-10 control-label ">
                              Repeticiones
                           </label>
                           <v-text-field
                            solo
                            type="number"
                            autocomplete="off"
                            id="ident"
                            v-model="ruta.repeticiones"
                            :rules="[rules.required]"
                            hint = "Este Campo es Obligatorio"
                            required
                            name="repeat"
                            placeholder="Cant de Rutas a crear">
                           </v-text-field>

                          </v-flex>
                        </v-layout>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogSerialize = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="serializeItem()">Clonar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogDetail" max-width="500px" max-height="700px" v-if="ruta">
                <v-card>
                  <v-card-title>Detallles de la ruta {{ ruta.name }}</v-card-title>

                    <v-card-text>Comentario: </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetail = false">Aceptar</v-btn>
                    <v-btn color="primary" text @click="detalles()" v-if = "ruta.is_editable">Detalles"</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogDelete" max-width="550px">
                <v-card>
                  <v-card-title>Alerta de Edicion</v-card-title>
                  <v-card-text>Desea Modificar la Ruta?</v-card-text>
                  <v-layout row>
                  <v-flex xs12 ma-2 pa-2>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.name')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="ruta.name"
                    @input="ruta.name = ruta.name.toUpperCase()"
                    v-validate="'required'"
                    name="name"
                    v-bind:placeholder="$t('lang.ruta.name')">
                   </v-text-field>
                   <v-checkbox
                      v-model="ruta.charter"
                      label="Charter"
                   ></v-checkbox>
                  <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.tarifa')}}
                   </label>
                   <v-text-field
                    solo
                    type="number"
                    autocomplete="off"
                    id="ident"
                    v-model="ruta.tarifaBase"
                    v-validate="'required'"
                    name="tarifa"
                    v-bind:placeholder="$t('lang.ruta.tarifa')">
                   </v-text-field>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.ruta.fee')}}
                   </label>
                   <v-text-field
                    solo
                    type="number"
                    autocomplete="off"
                    id="ident"
                    v-model="ruta.agencyFee"
                    v-validate="'required'"
                    name="fee"
                    v-bind:placeholder="$t('lang.ruta.fee')">
                   </v-text-field>
                  </v-flex>
                </v-layout>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="editItem()">Cambiar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>
<script>
import { RUTAS_LIST } from '../constants/graphql'
import { SERIALIZE_RUTA_MUTATION} from '../constants/graphql'
import { UPDATE_RUTA_MUTATION } from '../constants/graphql'
export default {
  name: 'RutasList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Nombre', value: 'name' },
          {text: 'Primer vuelo', value:'flightNumber'},
          { text: 'Origen', value: 'origen.city' },
          { text: 'Destino', value: 'destino.city' },
          { text: 'date', value: 'dateDeparture' },
          { text: 'Charter', value: 'charter'},
          {text: 'Action', value: 'actions'},
        ],
        dialogDelete: '',
        dialogDetail:'',
        dialogSerialize:'',
        ruta: '',
        name: null,
        allRutas:[],
        loading:false,
        rutaId:'',
        rutaName:'',
        charter:false,
        tarifa:0.0,
        fee:0.0,
        rules: {
         required: value => !!value || 'Required.',

        },
     };
  },
  components: {

  },
  props: {
  },
  computed: {
    registeredRutas () {
        return this.allRutas
      //return this.paxs.filter(paxs => paxs.is_editable)
    },

  },
  async mounted () {
     this.loadRutasList()
    },
  methods: {
  firstFlightNumber(flights){
    return JSON.parse(flights)[0].number
  },

   /* displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },*/
       /*selectItem: function(item) {
          if (confirm('Seleccionar este Ruta? ' )) {
                //this.$emit('paqueteselect', subscr.id)
                //this.$router.push('/pax/'+ item.passportNumber)
           }
        },*/

        addpkg: function() {
        },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addRuta(){if(confirm("Desea agregar una Ruta.")) this.$router.push('/newRuta')},

       async loadRutasList(){
         this.loading = true
         await this.$apollo.query({
                query: RUTAS_LIST,
                fetchPolicy: "network-only",
                }).then((data)=>{
                this.allRutas = data.data.allRutas
                this.loading = false
                })
       },

        showDeleteDialog(item) {
           this.ruta = item
           this.dialogDelete = !this.dialogDelete

        },
        showSerializeDialog(item) {
           this.ruta = item
           this.dialogSerialize = !this.dialogSerialize

        },
         showDetailDialog(item) {
            this.ruta = item
            this.dialogDetail = !this.dialogDetail
        },
        paxEditionEstatus(item){
             if(item.is_editable) {return "Borrador"} else {return "Cerrado"}
        },

        detalles: function(){
          /*  this.$router.push('/pax/'+ this.pax.passportNumber)
            this.dialogDuplicar = false
            */
          },
        async serializeItem() {
            if(this.ruta.frecuencia != null && this.ruta.repeticiones != null)
              {
                let ruta = this.ruta.id
                let frecuencia = this.ruta.frecuencia
                let repeticiones = this.ruta.repeticiones
                let username = "admin"
                this.$apollo.mutate({
                mutation: SERIALIZE_RUTA_MUTATION,
                variables: {
                    ruta,
                    frecuencia,
                    repeticiones,
                    username,
                }
                }).then(()=>{
                  this.dialogSerialize = false
                })
              }
              else{
                alert("Debe llenar los campos!")
              }
        },
        async editItem() {
                let id = this.ruta.id
                let name = this.ruta.name
                let charter = this.ruta.charter
                let tarifa = this.ruta.tarifaBase
                let fee = this.ruta.agencyFee
                this.$apollo.mutate({
                mutation: UPDATE_RUTA_MUTATION,
                variables: {
                    id,
                    name,
                    charter,
                    tarifa,
                    fee,
                }
                }).then(()=>{
                  console.log(name)
                  this.dialogDelete = false
                })



        },

        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
