<template>
  <div>


    <BlackList />

  </div>
</template>

<script>

import {nextTick} from 'vue'
import BlackList from '@/components/BlackList'


export default {
  name: 'AllBlackList',
  components: {
    BlackList,
  },

  data () {
    return {
      addToList : false,
      showList : true,
      buttonTag:"Agregar",
      airlineKey : 0,
      loading: true,
      reservaKey:0,

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createReserva = false
           this.buttonTag = "Agregar Reserva de Hostal"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addPax(){
      await nextTick()
      this.reservaKey = this.reservaKey + 1
      this.addToList = !this.addToList
   },

  },
}
</script>
