<template>
  <div>
     <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista Negra</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "showAddDialog"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="blackList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :key = "tableKey"


        >
            <template v-slot:item.pax="{ item }">
                <td>{{ item.pax.passportNumber}}, {{item.pax.firstName}} {{item.pax.familyName}} {{item.pax.movil}}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                     <div >

                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
         </v-card>
         <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Eliminacion</v-card-title>
                  <v-card-text>Desea Eliminar la Reserva?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
       <v-dialog v-model="dialogBook" max-width="500px">
        <v-card>
          <v-card-title>Poner en la lista negra</v-card-title>
          <v-flex xs12>
                    <label class="col-sm-10 control-label ">
                      ID
                   </label>
               <v-text-field
                    class="ma-2"
                    @input="passport = passport.toUpperCase()"
                    solo
                    id="passport"
                    v-model="passport"
                    v-validate="'required'"
                    name="passport">

               </v-text-field>
            </v-flex>
              <v-flex xs12>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.first_name')}}
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="last_name"
                    v-model="fullName"
                    @input="fullName = fullName.toUpperCase()"
                    v-validate="'required'"
                    name= "fullName">
                 </v-text-field>
            </v-flex>
          <v-card-actions>
            <v-btn color="primary"  @click="dialogBook = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary"  @click="addToBl()">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ALL_BLACK_LIST } from '../constants/graphql'
import { DELETE_BLACK_LIST_MUTATION } from '../constants/graphql'
import { ADD_TO_BLACKLIST_MUTATION } from '../constants/graphql'

import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'BlackList',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
  data() {
      return {
            search: '',
            headers: [
          {text: 'Nombre', value: 'fullName'},
          { text: 'Pasaporte', value: 'pp' },
          {text: 'Creado Por', value: 'createdBy'},
          {text: 'Opciones', value: 'actions'}

        ],
        allBlackList:[],
        dialogDelete: false,
        dialogBook: false,
        itemTo:'',
        tableKey:0,
        username: (JSON.parse(this.userStore.getUser)).username,
        groups: (JSON.parse(this.userStore.getUser)).groups,
        loading: false,
        buttonTag:"Agregar",
        passport:'',
        fullName:'',
     }
  },
  components: {
  },
  props: {
    reservas: {
      type: Array,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    blackList () {
      //return this.reservas

      return this.allBlackList
    },
    isAdmin(){
        if(this.groups)
          return this.groups.filter(group => group.id == 1).length > 0
        else
          return false

       /* if(this.username == "davide")
           return true
        else
           return false*/
       },

  },

async mounted () {
     this.loadBlackList()
    },
  methods: {

  showAddDialog(){
           this.dialogBook = true

      },
   showDeleteDialog(item){
            this.itemTo = item
            this.dialogDelete = true

      },

   deleteItem(){
      if(this.isAdmin()){
        const index = this.blackList.findIndex(itins => itins.id ==this.itemTo.id)
        console.log("updated_created>>"+index)
        if (index >= 0) this.blackList.splice(index, 1)
        this.cancelarReserva(this.itemTo.pp)
       }
      else {
        alert("Esta accion requiene permieso de administracion..")
      }
   },

   async addToBl(){
        var pp = this.passport
        var fullName = this.fullName
        this.$apollo.mutate({
        mutation: ADD_TO_BLACKLIST_MUTATION,
        variables: {
            pp,
            fullName

        }
        }).then(()=>{
           this.dialogBook = false
           this.tableKey = this.tableKey+1
           var pax = {
           pp: this.passport,
           fullName: this.fullName,
           createdBy: 'admin'
           }
           this.allBlackList.push(pax)
           //this.loadAllReservas()
        }
        )
   },

   async cancelarReserva(pp){
        this.$apollo.mutate({
        mutation: DELETE_BLACK_LIST_MUTATION,
        variables: {
            pp

        }
        }).then(()=>{
           this.dialogDelete = false
           this.tableKey = this.tableKey+1
           //this.loadAllReservas()
        }
        )
   },

   async loadBlackList(){
     this.loading = true
     await this.$apollo.query({
            query: ALL_BLACK_LIST,
            fetchPolicy: "network-only",
            }).then((data)=>{
            this.allBlackList = data.data.allBlacklist
            this.loading = false
            })
   },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
