<template>
  <div class="container">
    <v-app>
    <app-navigation :key="$route.fullPath"></app-navigation>
        <v-main transition="slide-x-transition">
        <router-view>

      </router-view>
    </v-main>
    <v-spacer></v-spacer>
    <v-footer class="bg-grey-lighten-1 mt-10">
    <v-row justify="center" no-gutters>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — Kings Journey SGB System. Version: {{ version }}
      </v-col>

    </v-row>
  </v-footer>
    </v-app>
    </div>

</template>

<script>
//v-if="!$route.meta.hideNavbar"
import AppNavigation from '@/components//AppNavigation';
export default {

    name: 'App',
    data: () => ({
      isEditing: true,
      links: [
        'Inicio',

      ],
      version: "2024-05-11"
     }),
    created () {
            document.title = "Kings Journey";
        },

    components: {
        AppNavigation,
    }
};
</script>
<style>
</style>